import React, { useState,useEffect,useRef } from 'react';
import {useNavigate,Link, Navigate} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './userset.css'
import '../userdashboard/userdash.css'
import Nav from '../../components/nav/Nav';
import { FaArrowLeftLong } from "react-icons/fa6";
import { RiMessage2Fill } from "react-icons/ri";
import img13 from '../../components/images/Newfolder/goodd-removebg-preview.png';
import { IoIosNotifications } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import img12 from '../../components/images/Newfolder/user3d-removebg-preview.png';
import { FaUser } from "react-icons/fa6";
import {MdSpaceDashboard} from 'react-icons/md'
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import { LiaTruckLoadingSolid } from "react-icons/lia";

const Userssettings = () => {
   
     //const [submitting, setSubmitting] = useState(false);
  //let bgvisible= submitting?"blurry":"visible";
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageerr, seterr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSave = async (e) => {
        e.preventDefault(); 
        try {
          setLoading(true);
            const response = await axios.post('https://api.kefetastartups.com/usersettings', {
                oldPassword,
                newPassword,
                email,
            });

            const data = response.data;

            if (response.data.success) {
                setMessage(data.message);
                seterr(true);
                //sessionStorage.clear();
                // sessionStorage.setItem('email', data.updatedEmail);
                navigate('/userssettings');
            } else {
              seterr(false)
                 setLoading(false);
            }
        } catch (error) {
            setMessage('Oops! Update not successful. check your credentials.');
            seterr(false)
            console.error('Error updating settings:', error);
            setLoading(false);
        }
    };
     
    
    const {t,i18n}=useTranslation(); 
    
    const handleToggleSeen = (index) => {
      const updatedNotifications = [...notifications];
      updatedNotifications[index].seen = !updatedNotifications[index].seen;
      setNotifications(updatedNotifications);
    
      // Save updated notifications to localStorage
      localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
    };
    const toggleRef = useRef(false);
    const [visible, setVisible] = useState(false);

    const closepopfun=()=>{
  setMessage(false)
}



const handleClick = () => {
  toggleRef.current = !toggleRef.current;
  setVisible(toggleRef.current);
};





    const handleLogout = () => {
      // Clear the session
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    };
    
    const userSessionEmail = sessionStorage.getItem('email');
    const FirstName = sessionStorage.getItem('FirstName');
    const LastName = sessionStorage.getItem('LastName');
    const phonenumber = sessionStorage.getItem('phonenumber');

    const [isHovered, setIsHovered] = useState(false);
    const cardRef = useRef(null);
  
    const handleMouseMove = (e) => {
      const cursorCircle = document.getElementById('cursor-circle');
      cursorCircle.style.left = `${e.pageX}px`;
      cursorCircle.style.top = `${e.pageY}px`;
  
      // Check if the cursor circle is hovering over the card
      const cardRect = cardRef.current.getBoundingClientRect();
      const isHovered = e.clientX >= cardRect.left && e.clientX <= cardRect.right &&
                        e.clientY >= cardRect.top && e.clientY <= cardRect.bottom;
      setIsHovered(isHovered);
    };

  
    return (
        <div  className={`userdash userdash_set`}>
          <div className='navuser'>
          <Nav truth="yes"/>
          </div>

      <div className="glasseffect glasseffect_settings" ref={cardRef} onMouseMove={handleMouseMove}>
        
      <div id="cursor-circle" className={`cursor-circle ${isHovered ? 'active' : ''}`}></div>
      {/* <div className="secret-text">
        Hover me to reveal the secret!
      </div> */}


<div className="userdash_main userdash_main_settings">
    <div className='backnavigationandhead'>
       <Link to='/userdash'><FaArrowLeftLong /></Link>
<h2>{t("userdash.ChangeProfile")} </h2>
</div>
 <form>


<div>
    <label>{t("userdash.PreviousPassword")}:</label>
     <input
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          className="input"
        />
      </div>

      <div>
        <label className="label">{t("userdash.PreviousEmail")}:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input"
        />
      </div>




        <div>
        <label className="label">{t("userdash.NewPassword")}:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="input"
        />
      </div>

        
      <div className="usersettingbutton usersettingbutton2">
      <input style={{backgroundColor:"blue",cursor:"pointer",color:"white"}} type='button' onClick={(e)=>{handleSave(e)}} value={i18n.language==="en"?"Save":"ይስተካከል"}/>
        
      
      {loading && <p style={{width:"170px",margin:"40px -120px 0 0",color:"white"}}>{t("userdash.onesec")}</p>}


      {
      message && 
      <div style={{background:`${messageerr?'#ececec':""}`, color:"blue"}}  className="usersetmessage">
      {messageerr &&<p >{t("userdash.Loginseeupdate")} <span><Link to="/login">{t("login.Login2")}</Link></span> </p>}
      <p>{message}</p>
      {!messageerr && <p className='closebtn_usrdash' onClick={closepopfun}
      
      >Close</p>}
      </div>
      }
      </div>

      

</form>
{
visible &&<div className={`logout-cont ${toggleRef}`} style={{zIndex:"70"}}>
    <h2>{t("userdash.Logout")}</h2>
    <div>
    <button onClick={handleLogout}>
          {t("userdash.Ok")}
      </button>
    <Link onClick={handleClick} to='/userssettings'>
         {t("userdash.Cancel")} 
      </Link>
      </div>
</div>
      }
</div>

<sidebar className='dash_side'>
<div className='dash_side_cont'>
<div className="dash_side_profile">
  <img src={img12} alt=""/>
  <h4>{FirstName} {LastName}</h4>

  <div className="dash_side_profile_info">

<p>{t("login.Email")}<span>{userSessionEmail}</span></p>
<p>{t("apply.Phone")}<span>{phonenumber}</span></p>
  </div>


</div>


<ul className="dash_side_bottom">
  
  <li>
  <Link to="/usernotification">
    <IoIosNotifications  className='dashuser-icons'  style={{color:"black"}}/> 
    
  <p>{t("userdash.Notification")}</p> </Link>
  </li>
   
   <li><Link to="/usersendmessagetous"><RiMessage2Fill  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.sendmessage")}</p> </Link></li>
  
  <li><Link to="/userssettings"><FaUser  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Settings")}</p> </Link></li>
  <li  onClick={handleClick}>  <Link to=""><AiOutlineLogout  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Logout")}</p> </Link></li>

</ul>

<div className='samp'>
  <img src={img13} alt=''/>
  <p>{t("userdash.contactanytime")}</p>


</div>

</div>
</sidebar>

      </div>
      </div>
    );
  };
  
  export default Userssettings;
  