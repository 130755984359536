import React,{useState,useRef,useEffect} from 'react'
import {Link} from 'react-router-dom'
import './Dashboard.css'
import '../../pages/itemlists.css'
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useNavigate, unstable_useBlocker } from 'react-router-dom';
import Search from '../../components/search/Search'
import Nav from '../../components/navadmin/Nav'
import Sidebar from '../../components/sidebar/Sidebar'
import Customercount from '../../components/customercount/Customercount'
import { Redirect } from 'react-router-dom';
function Dashboard() {
  
  /* month and day remaining calculator*/
  const [applications, setApplications] = useState([]);
  const [search, setSearch] = useState(applications);
  const [searchByChoice, setSearchByChoice] = useState('company_name');
  const [successMessage, setSuccessMessage] = useState(null);

 
  useEffect(() => {
    // Fetch data from the server when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.kefetastartups.com/applications/getapplications');
        setApplications(response.data.application);
        //console.log(response.data.application);
        setSearch(response.data.application);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount
  




      //pdf export
      const tableRef = useRef(null);


const filterby = (e) => {
  const search = e.target.value.toLowerCase();
  const filteredNames = applications.filter(searchs => {
    const companyNameMatch = searchs.f_name.toLowerCase().includes(search);

    if (searchByChoice === "name" && companyNameMatch) {
      return true;
    } 
    return false;
  });

  setSearch(filteredNames);
};

const [showPopup, setShowPopup] = useState(false);

const closePopup = () => {
  setShowPopup(false); // Close the popup
  window.location.reload(false)
};




  const filterName = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = applications.filter(
      (searchs) => searchs.f_name.toLowerCase().includes(searchValue)
    );
    setSearch(filteredData);
  };


      const [visible, setVisible] = useState(false);
      const toggleRef = useRef(false);

    let backopacity=visible?"blurry":"visible";



      /* send message functionality */
      const [inputType, setInputType] = useState('text');

      const handleRadioChange = (event) => {
        setInputType(event.target.value === 'phone' ? 'text' : 'email');
      };

      const handleLogout = () => {
        // Clear the session
        sessionStorage.clear();
        localStorage.clear();
        // Redirect the user to the login page
        window.location.href = '/';
      };


  const exportPDFAndExcel = () => {
    const exportFormat = prompt("Choose export format: 'pdf' or 'excel'");
    if (exportFormat === 'pdf') {
      exportPDF();
    } else if (exportFormat === 'excel') {
      exportExcel();
    } else {
      alert("Invalid export format! Please choose 'pdf' or 'excel'.");
    }
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
  
    doc.setFontSize(15);
    const title = 'Users Information';
    const headers = [[ 'Name','Email','Company name','Number', 'Description','Attachment']];
    let data = []
     data= search.map(items=>{
     return(
        [
          [[ items.f_name ] +" "+[items.lastname]],
             [items.email],
             [items.company_name ],
             [items.phone_number ],
             [items.description ],
             [items.file_path ],

             
        ]
     )
    })
  
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('table.pdf');
  };

  
  const exportExcel = () => {
    const headers = ['Name', 'Email', 'Company name', 'Number', 'Description', 'Attachment'];
    let data = search.map((item) => [
      item.f_name + ' ' + item.lastname,
      item.email,
      item.company_name,
      item.phone_number,
      item.description,
      item.file_path
    ]);

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table.xlsx');
  };


  const [isMesgAvtivate, setisMesgAvtivate] =useState(false);
  const [Mesg, setMesg] =useState('');

  const activateMessage=(message)=>{
    setisMesgAvtivate(true);
    setMesg(message)
    setMesg(message)
   }

 const closeMessage=()=>{
  setisMesgAvtivate(false);
 }


    const [isLoggedIn, setIsLoggedIn] = useState(true); // Track user's login state

  // Function to check if user is logged in
  const checkLoggedIn = () => {
    const token = sessionStorage.getItem('token');
    return !!token; // Return true if token exists, false otherwise
  };

  useEffect(() => {
    // Check if user is logged in when component mounts
    setIsLoggedIn(checkLoggedIn());
  }, []);

  console.log('isLoggedIn: ' + isLoggedIn);

  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);


  // Conditional rendering based on isLoggedIn
  if (!isLoggedIn) {
    return(
    <div className="popupfor_redirct">
      <div className="popup-redirect-content">
        <p>Please click here to login</p>
        <Link to='/login'>Login</Link>
      </div>
    </div>
    )
     }

  
     else if(isLoggedIn){
      if(isMobile){
        return(
          <div className="Mobile_checkcontainer">
          <p className="message">Use Desktop/Laptop device for optimal usage.</p>
          <button onClick={handleLogout}>Go back</button>
        </div>
        )
      }else if(!isMobile){
  return (
    <div className='dashboard' >
      <div className={`popup ${showPopup ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>Save successful!</p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>
<div className="dashboard_contents" >

<div className={`${backopacity}`}>
<Sidebar/>
     </div>
      <main className="dashboard-main main-section">
      <Nav/>
      

       <div className={`dashboard-main-shortcuts ${backopacity}`}>
      
        <h1 className={`${backopacity}`}>Dashboard</h1>
        
        <ul>
  
        <Link to="">
      <Customercount/>
    </Link>
  
</ul>

        
       
       </div>


 {/* table */}
<div className="dash-tables" >
<div className="headAndsearch">
{successMessage && <div className='success-messagee'>{successMessage}</div>}
<h3 className={`${backopacity}`}>Startups Information</h3> 

{/* <div style={{visibility:'hidden'}}>
            <label htmlFor="searchByChoice">Search By:</label>
            <input type="text" value="Company_name"
              id="searchByChoice"
              name="searchByChoice"
              onChange={filterby}
            />
              
          </div> */}
         
         { <Search
            handleclick={filterName}
            handleclicksearch={filterby}
          /> }

<p  onClick={exportPDFAndExcel}>Export PDF/Excel</p>
          
<td className='checkd-btn' style={{backgroundColor:"white"}}></td>
 
</div>

<table className={`${backopacity}`}>

<tr>

<th>Name</th>
            <th>Email</th>
            <th>Company Name</th>
            <th>Company status</th>
            <th>Description</th>
            <th>file Attachment</th>
            
</tr>
{search.map((item) => (
            <tr key={item.id}>
            
            <td>{item.f_name} {item.lastname}</td>
              <td>{item.email}</td>
              <td>{item.company_name}</td>
              <td  style={{ color: item.userstatus === 'accepted' ? 'green':  item.userstatus === 'rejected' ? 'red': item.userstatus === 'pending' ? 'orange': 'black' }}>
              
      {item.userstatus}
    </td>
             <td  onClick={() => activateMessage(item.description)}><p className='seemsgbtn' >Description</p></td>
    <td className='file_att_path'>
                  {item.file_path ? (
                    <a href={`http://api.kefetastartups.com/uploads/${item.file_path}`} download>
                      Download File
                    </a>
                  ) : 'No file available'}
                </td> 
            </tr>
          ))}
</table>


</div>

{
  isMesgAvtivate &&

  <div className="isMesgAvtivatemessg1" >
    
    <div>
<h3>Description</h3>
<hr/>
<p>{Mesg}</p>
</div>
<button onClick={() => closeMessage()}>Close</button>

  </div>
}
      </main>
     
    
    </div>
    
    </div>
  );}
}
};

export default Dashboard;