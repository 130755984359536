import React from 'react';
import './Search.css';

function Search(props) {
  return (
    <>
      <div className="container">
        <form>
          <div>
            <label htmlFor="search">Filter:</label>
            <input
              type="text"
              id="search"
              style={{ width: "90px", marginRight: "15px", padding: "0 5px", height: "17px" }}
              name="search"
              onChange={props.handleclick}
            />
          </div>

          <div style={{visibility:'hidden'}}>
            <label htmlFor="searchByChoice">Search By:</label>
            <input type="text" value="Company_name"
              id="searchByChoice"
              name="searchByChoice"
              onChange={(e)=>props.handleclicksearch(e)}
            />
              
          </div>
        </form>
      </div>
    </>
  );
}

export default Search;
