export default[
    {
    header:"የገንዘብ ድጋፍ ",
    text:"ልዩ እና ተፅዕኖ ፈጣሪ ኢንተርፕራይዞችን የገንዘብ ድጋፍ ለማሳደግ፣ ግብዓቶችን፣ ዘዴዎችን እና መድረኮችን እናቀርባለን።"
     },
    {
    header:"የስራ መስራቾች",
    text:"በወሳኝ ደረጃ ላይ የተመሰረተ የአሰራር ዘዴን በመከተል እና ወደ ማህበራዊ ፋይናንሺያል ሀብቶች ተደራሽነታቸውን በማስፋት የኢንቨስትመንት ዝግጁነታቸውን ለመስራቾች እናፋጥናለን።"
     },
    {
    header:"የተለያዩ ጠቃሚ የቢዝነስ ሀብቶች",
    text:"ለጀማሪዎች ምርታማነታቸውን፣ ጥንካሬያቸውን፣ተፅዕኖአቸውን እና አካታችነታቸውን ለማሳደግ ልዩ ግብዓቶችን እናቀርባለን።"
     },
    {
    header:"የኢንዱስትሪ ተሳትፎ",
    text:"ከኢንዱስትሪ መሪዎች እና ከሌሎች ባለድርሻ አካላት ጋር የሚደረግ ተሳትፎ።"
     },
]