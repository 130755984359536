import React from 'react';
import { Link, useParams } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import chaptereerPDF1 from "./chaptereer.pdf";
import chaptereerPDF2 from "./Chapter 2.pdf";
import chaptereerPDF3 from "./Chapter 3.pdf";
import chaptereerPDF4 from "./Chapter 4.pdf";
import chaptereerPDF5 from "./Chapter 5.pdf";
import chaptereerPDF6 from "./Chapter 6.pdf";
import chaptereerPDF7 from "./Chapter 7.pdf";
import chaptereerPDF8 from "./Chapter 8.pdf";
import chaptereerPDF9 from "./Chapter 9.pdf";
import chaptereerPDF10 from "./Chapter 10.pdf";
import chaptereerPDF11 from "./Chapter 11.pdf";
import chaptereerPDF12 from "./Chapter 12.pdf";
import "./usermodchap.css";
import ReactGA from "react-ga4"
import {useEffect} from "react"
function DocViewerComponent() {

useEffect(()=>{

  ReactGA.event({
      category: 'User view module',
      action: 'Clicked View',
      label: 'Clicked View to see module',
    });
    
},[])


 const { head } = useParams();

 // Define an array of PDF documents
 const pdfDocuments = [
  chaptereerPDF1,
  chaptereerPDF2,
  chaptereerPDF3,
  chaptereerPDF4,
  chaptereerPDF5,
  chaptereerPDF6,
  chaptereerPDF7,
  chaptereerPDF8,
  chaptereerPDF9,
  chaptereerPDF10,
  chaptereerPDF11,
  chaptereerPDF12,
 ];

 // Determine the index of the PDF document based on the value of 'head'
 let pdfIndex;
 switch (head) {
  case "Chapter 1":
   pdfIndex = 0;
   break;
  case "Chapter 2":
   pdfIndex = 1;
   break;
  case "Chapter 3":
   pdfIndex = 2;
   break;
  case "Chapter 4":
   pdfIndex = 3;
   break;
  case "Chapter 5":
   pdfIndex = 4;
   break;
  case "Chapter 6":
   pdfIndex = 5;
   break;
  case "Chapter 7":
   pdfIndex = 6;
   break;
  case "Chapter 8":
   pdfIndex = 7;
   break;
  case "Chapter 9":
   pdfIndex = 8;
   break;
  case "Chapter 10":
   pdfIndex = 9;
   break;
  case "Chapter 11":
   pdfIndex = 10;
   break;
  case "Chapter 12":
   pdfIndex = 11;
   break;
  default:
   // Default to the first document if 'head' doesn't match any case
   pdfIndex = 0;
 }

 // Log the 'head' value and the determined 'pdfIndex'
 console.log("Head:", head);
 console.log("PDF Index:", pdfIndex);

 // Select the PDF document based on the index
 const selectedPDF = pdfDocuments[pdfIndex];

 return (
  <div>
   <div className="pdfviewerpage">
    <DocViewer documents={[{ uri: selectedPDF }]} pluginRenderers={DocViewerRenderers} scale={12}  />
   </div>
   
  </div>
 );
}

export default DocViewerComponent;
