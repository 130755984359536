import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../userdashboard/userdash.css';
import Nav from '../../components/nav/Nav';
import Data from './data';
import chaptereer from '../usermodulechap/chaptereer.pdf';
import chaptereerPDF12 from '../usermodulechap/Chapter 12.pdf';
import chaptereerPDF11 from '../usermodulechap/Chapter 11.pdf';
import chaptereerPDF10 from '../usermodulechap/Chapter 10.pdf';
import chaptereerPDF9 from '../usermodulechap/Chapter 9.pdf';
import chaptereerPDF8 from '../usermodulechap/Chapter 8.pdf';
import chaptereerPDF7 from '../usermodulechap/Chapter 7.pdf';
import chaptereerPDF6 from '../usermodulechap/Chapter 6.pdf';
import chaptereerPDF5 from '../usermodulechap/Chapter 5.pdf';
import chaptereerPDF4 from '../usermodulechap/Chapter 4.pdf';
import chaptereerPDF3 from '../usermodulechap/Chapter 3.pdf';
import chaptereerPDF2 from '../usermodulechap/Chapter 2.pdf';
import { RiMessage2Fill } from 'react-icons/ri';
import img11 from '../../components/images/Newfolder/message2-removebg-preview.png';
import img13 from '../../components/images/Newfolder/goodd-removebg-preview.png';
import img12 from '../../components/images/Newfolder/user3d-removebg-preview.png';
import { IoIosNotifications } from 'react-icons/io';
import { FaUser } from 'react-icons/fa6';
import { AiOutlineLogout } from 'react-icons/ai';
import ReactGA from "react-ga4"
import { useTranslation } from 'react-i18next'

const Userdash = () => {
  const [error, setError] = useState('');
  const [userStatus, setUserStatus] = useState('');

  useEffect(() => {
    fetchUserStatus();
  }, []);


 const {t}=useTranslation(); 

  const fetchUserStatus = async () => {
    try {
      const sessionEmail = sessionStorage.getItem('email');
      const response = await axios.get(
        `https://api.kefetastartups.com/recievestatusdash/recivestatus?sessionemail=${sessionEmail}`
      );

      setUserStatus(response.data);
    } catch (error) {
      setError('Error fetching user status');
    }
  };

  const toggleRef = useRef(false);
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    toggleRef.current = !toggleRef.current;
    setVisible(toggleRef.current);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };

  const userSessionEmail = localStorage.getItem('email');
  const FirstName = localStorage.getItem('FirstName');
  const LastName = localStorage.getItem('LastName');
  const phonenumber = localStorage.getItem('phonenumber');

  const handleDownload = (notificationhead) => {
    let chaptername = '';
    let chapternamelink;
    // Create a temporary link element
    const link = document.createElement('a');
    switch (notificationhead) {
      case 'Chapter 1':
        chaptername = 'Chapter 1';
        chapternamelink = chaptereer;
        break;
      case 'Chapter 2':
        chaptername = 'Chapter 2';
        chapternamelink = chaptereerPDF2;
        break;
      case 'Chapter 3':
        chaptername = 'Chapter 3';
        chapternamelink = chaptereerPDF3;
        break;
      case 'Chapter 4':
        chaptername = 'Chapter 4';
        chapternamelink = chaptereerPDF4;
        break;
      case 'Chapter 5':
        chaptername = 'Chapter 5';
        chapternamelink = chaptereerPDF5;
        break;
      case 'Chapter 6':
        chaptername = 'Chapter 6';
        chapternamelink = chaptereerPDF6;
        break;
      case 'Chapter 7':
        chaptername = 'Chapter 7';
        chapternamelink = chaptereerPDF7;
        break;
      case 'Chapter 8':
        chaptername = 'Chapter 8';
        chapternamelink = chaptereerPDF8;
        break;
      case 'Chapter 9':
        chaptername = 'Chapter 9';
        chapternamelink = chaptereerPDF9;
        break;
      case 'Chapter 10':
        chaptername = 'Chapter 10';
        chapternamelink = chaptereerPDF10;
        break;
      case 'Chapter 11':
        chaptername = 'Chapter 11';
        chapternamelink = chaptereerPDF11;
        break;
      case 'Chapter 12':
        chaptername = 'Chapter 12';
        chapternamelink = chaptereerPDF12;
        break;
      default:
        chaptername = 'Chapter 8';
        chapternamelink = chaptereerPDF8;
        break;
    }

    link.href = chapternamelink;
    link.download = chaptername;
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);

  ReactGA.event({
      category: 'User download',
      action: 'Clicked download',
      label: 'user downloaded module',
    });
   
  };

  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);

  const handleMouseMove = (e) => {
    const cursorCircle = document.getElementById('cursor-circle');
    cursorCircle.style.left = `${e.pageX}px`;
    cursorCircle.style.top = `${e.pageY}px`;

    // Check if the cursor circle is hovering over the card
    const cardRect = cardRef.current.getBoundingClientRect();
    const isHovered =
      e.clientX >= cardRect.left &&
      e.clientX <= cardRect.right &&
      e.clientY >= cardRect.top &&
      e.clientY <= cardRect.bottom;
    setIsHovered(isHovered);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true); // Track user's login state

  // Function to check if user is logged in
  const checkLoggedIn = () => {
    
    const token = localStorage.getItem('isLoggedIn');
    return !!token; // Return true if token exists, false otherwise
  };

  useEffect(() => {
    // Check if user is logged in when component mounts
    setIsLoggedIn(checkLoggedIn());
  }, []);

  console.log('isLoggedIn: ' + isLoggedIn);


  // Conditional rendering based on isLoggedIn
  if (!isLoggedIn) {
    return(
    <div className="popupfor_redirct">
      <div className="popup-redirect-content">
        <p>{t("userdash.clicktologin")}</p>
        <Link to='/login' className='unattorizedredirect-btn'>{t("login.Login2")}</Link>
      </div>
    </div>
    )
     }
  
  else if(isLoggedIn){
  return (
    <div className="userdash">
      <div className="navuser">
        <Nav 
        truth='yes'
        />
      </div>

      <div className="glasseffect" ref={cardRef} onMouseMove={handleMouseMove}>
        <div id="cursor-circle" className={`cursor-circle ${isHovered ? 'active' : ''}`}></div>

        <div className="userdash_main">
          <div className="userdash_main_top">
            <div className="userdash_main_top_welcome">
              <h2>{t("userdash.Selam")} {FirstName}!</h2>
              <p>{t("userdash.greetings")}</p>
            </div>
          </div>

          {userStatus === 'pending' ? (
            <div className="userdash_bottom userdash_bottom2">
              <div className="userdash_bot_headi">
                <h2>{t("userdash.Aboutmodule")}</h2>
                <p>{t("userdash.onceapproved")} </p>
              </div>

              <ul className="userdash_bot_outlines">
                <li>
                  <span className="outlinid">1</span>
                  <div>
                    <h3>Introduction to Entrepreneurship</h3>
                    <p>
                      Introduction to Entrepreneurship offers a comprehensive understanding of business fundamentals,
                      innovation, and startup strategies.{' '}
                    </p>
                  </div>
                </li>

                <li>
                  <span className="outlinid">2</span>
                  <div>
                    <h3>Ideation and validation</h3>
                    <p>
                      Learn to generate, refine, and validate ideas effectively, crucial for innovation and
                      problem-solving in various fields post-completion..{' '}
                    </p>
                  </div>
                </li>

                <li>
                  <span className="outlinid">3</span>
                  <div>
                    <h3>Business Model Canvas</h3>
                    <p>Learn to develop, analyze, and refine business ideas comprehensively. </p>
                  </div>
                </li>
                <li>
                  <span className="outlinid">4</span>
                  <div>
                    <h3>customer discovary and acquisition</h3>
                    <p>You will learn methods for understanding customer needs and acquiring them. </p>
                  </div>
                </li>
              </ul>
            </div>
          ) : userStatus === 'accepted' ? (
            <div className="userdash_bottom">
              <h3 style={{marginTop:"24px"}}>{t("userdash.YourModules")}</h3>

              {Data.length > 0 ? (
                <ul className="userdash_bottom_contents">
                  {Data.map((notification, index) => (
                    <li key={index}>
                      <img src={img11} alt="" />
                      <div className="userdash_message">
                        <h4>{notification.head}</h4>
                        <p className={notification.seen ? 'seen' : ''}>{notification.text}</p>
                      </div>
                      <div className="message_actionicons">
                        <Link to={`/docViewerComponent/${notification.head}`}> {t("userdash.View")}</Link>
                        <button style={{cursor:"pointer"}}onClick={() => handleDownload(notification.head)}> {t("userdash.Download")}</button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t("userdash.notactive")}</p>
              )}

              {visible && (
                <div className={`logout-cont ${toggleRef}`} style={{ zIndex: '70' }}>
                  <h2>{t("userdash.Lgout")}</h2>
                  <div>
                    <button style={{background:"red"}} onClick={handleLogout}>{t("userdash.Ok")}</button>
                    <Link onClick={handleClick} to="/userdash">
                    {t("userdash.Cancel")}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className='dash_noData'>{t("userdash.nodata")}</div>
          )}
        </div>

        <aside className="dash_side">
          <div className="dash_side_cont">
            <div className="dash_side_profile">
              <img src={img12} alt="" />
              <h4>
                {FirstName} {LastName}
              </h4>

              <div className="dash_side_profile_info">
                <p>
                  {t("login.Email")}<span>{userSessionEmail}</span>
                </p>
                <p>
                  {t("apply.Phone")}<span>{phonenumber}</span>
                </p>
              </div>
            </div>

            <ul className="dash_side_bottom">
              <li>
                <Link to="/usernotification" className="sidelinksusers">
                  <IoIosNotifications className="dashuser-icons" style={{ color: 'black' }} />
                  <p>{t("userdash.Notification")}</p>
                </Link>
              </li>
              <li>
                <Link to="/usersendmessagetous" className="sidelinksusers">
                  <RiMessage2Fill className="dashuser-icons" style={{ color: 'black' }} />
                  <p>{t("userdash.sendmessage")}</p>
                </Link>
              </li>
              <li>
                <Link to="/userssettings" className="sidelinksusers">
                  <FaUser className="dashuser-icons" style={{ color: 'black' }} />
                  <p>{t("userdash.Settings")}</p>
                </Link>
              </li>
              <li onClick={handleClick}>
                <Link to="">
                  <AiOutlineLogout className="dashuser-icons" style={{ color: 'black' }} />
                  <p>{t("userdash.Logout")}</p>
                </Link>
              </li>

              {visible && (
                <div style={{background:"#ececec"}} className={`logout-cont ${toggleRef}`}>
                  <h2>{t("userdash.Logout")}</h2>
                  <div>
                    <button onClick={handleLogout}>{t("userdash.Ok")}</button>
                    <Link onClick={handleClick} to="">
                    {t("userdash.Cancel")}  
                    </Link>
                  </div>
                </div>
              )}
            </ul>

            <div className="samp">
              <img src={img13} alt="" />
              <p>{t("userdash.contactanytime")}</p>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );}
};

export default Userdash;
