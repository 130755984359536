import React,{useState,useEffect} from 'react'
import img1 from '../images/Newfolder/stat-removebg-preview.png'
import '../../pages/dashboard/Dashboard.css'



function Customercount() {
    const [rowCount, setRowCount] = useState(null);

    useEffect(() => {
      fetch('https://api.kefetastartups.com/user/countcompanies') 
        .then(response => response.json())
        .then(data => setRowCount(data.row_count))
        .catch(error => console.error('Error fetching data:', error));
    }, []);
  
    return (
      <li>
      <div>
        <h2>{rowCount !== null ? rowCount : 'Loading...'}</h2>
        <p>Applied count</p>
        </div>
        <img src={img1} alt=''/>
       
</li>
  )
}

export default Customercount

