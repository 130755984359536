import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'am',
     // Specify a fallback language
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], // Define the order of language detection
    },
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'html') return value;
        return value;
    }
},

    lng: 'en',
    resources: {
      en: {
        translation: {

            // navigaion
          homeNav: {
          
             offerService:"Service and offers",  
            navabout: 'About',
            navapply:"Apply", 
            blog:"Blogs ", 
            info:"Information"        
          },

          //hero
          homeHero: {
            heroslogan:"Elevating Your",
            heroslogan2:"Dreams Into Reality",
            heromessage: 'We give <b>startups opportunity</b> to accelerate through mentor-guidance and extensive resources by <b>providing the tools needed to gain</b> a competitive advantage.',
           herobtn:"Apply",    
           feedbacktop:"Glad to receive feedback from you",        
           feedbackhead:"Please provide us your feedback below",        
           feedbacksend:"Send",        
           feedbackcancel:"Cancel",        
           investors:"investors",  
           forinvestors:"For Investors",       
          },
          //herocontact
          homecontact: {
            Contactus:"Contact us",
            Through:"Through",
            EthiopiaAddisAbaba: 'Ethiopia, Addis Ababa',
               
          },

          // about home 
          homeabout: {
            aboutHead:"Who we are",
            aboutHead2: 'How we work',
            aboutMessege1:"Founded in Ethiopia, Kefeta  is a premier startup accelerator dedicated to supporting early-stage startups with high potential. We offer a dynamic program designed to nurture your ideas, refine your business model, and accelerate your journey to market success. In this crowded market place we offer you tools to make your business stand out.",
            aboutMessege2:"<strong>Mentorship:</strong>  Creating connections with industry leaders, experienced entrepreneurs, and experts who will guide you through every stage of your startup journey. </br>  <strong>Funding:</strong> Gain access to seed funding and investor networks to secure the capital you need to grow.  </br>   <strong>Community: </strong>  Join a vibrant community of like-minded entrepreneurs who share your passion for innovation and success."
          ,aboutButton:"Read More",
          roadHeading:"You are <span style={{color:'#fd8349'}}>family </span>now!",
          ServiceAndOffers:"Service And Offers",
          Providetoolsfor:"Our Services FOR ",
          Startups:"<span>START-UPS.</span>",
          },
          // step home 
          homestep: {
            stepHeading:"Ho<span style={{paddingBottom:'3px',borderBottom:'1px solid black'}}>w to get inv</span>olved",
            stepHead1: 'Funding',
            stepMessege1:"We will provide ETB 10,000 to get things started and grow as needed. 10,000 ETB for first round makers and increases as the progress",
            stepHead2: 'Office space',
            stepMessege2:"If needed, we shall provide office space as well as assistance in locating and choosing service suppliers. additionally, connections to prospective collaborators.",
            stepHead3: 'Mentor founders',
            stepMessege3:"Join our growing network of skilled mentors and entrepreneur.",
           },
          // investors
          investors: {
            FORINVESTORS:"FOR INVESTORS",
            Accesshig: 'Access to High-Potential Start-ups',
            firstText:'Gain access to a carefully selected portfolio of start-ups that have undergone rigorous evaluation and mentorship. Participate in exclusive investment rounds and funding opportunities tailored to meet your investment criteria.',
            StrategicNetworking: 'Strategic Networking',
            SECText:'Engage with other investors, industry leaders, and start-up  founders at our specially organized meetups and networking events. Explore potential partnerships and collaborations with innovative start-ups that  align with your strategic interests.',
            DueDiligencesupport:"Due Diligence support",
            ThiredText:'Receive detailed profiles and performance metrics of potential investment candidates to aid in your decision-making process. -Leverage our expert team to assist with the due diligence process, ensuring you have all necessary information to make informed investment decisions.',
            WHYPARTNER: 'WHY PARTNER WITH US?',
            ExtensiveNetwork:"Extensive Network",
            FourthText:'Tap into our global network of partners, mentors, and alumni to access a broad range of investment opportunities. Engage with a diverse and inclusive community of entrepreneurs and fellow investors, fostering innovation and collaboration.',
            ProvenRecord: 'Proven Track Record',
            FivethText:'Benefit from our track record of successful start-up exits and high returns on investment. Partner with an accelerator program that prioritizes measurable impact and long-term success.',
            asidehead: 'Explore further',
            asidelink1: 'About Us',
            asidelink2: 'Blogs And News',
            },

         // footer
         footer: {
            about:"About",
            aboutus: "About Us",
            Contact: 'Contact',
            Email:"Email",
            Phone: 'Phone',
            TERMSOFSERVICE:"TERMS OF SERVICE",
            TERMS:"Terms",
            PRIVACY:"PRIVACY",
            Language:"Language",
            rights:"All rights reserved",
            rights2:"Kefeta Startup Accelerator",
            apply:"Apply",
            info:"Information"
           },
         // apply
         apply: {
            noteHead:"Some Notes",
            notes1: "As you progress through this journey, you'll likely find yourself pleasantly surprised by the significant improvements and valuable knowledge you've gained along the way.",
            notes2: " Reflecting on your growth and accomplishments, you'll realize the remarkable strides you've made, both personally and professionally.",
            notes3: "Embrace the journey, for it holds the potential to exceed even your own expectations, leaving you with a profound sense of achievement and fulfillment.",
            apply:"Apply",
            CustomerSupport: 'Customer Support',
            Email:"Email",
            Phone: "Phone",
            FirstName:"First Name",
            LastName:"Last Name",
            Password:"Password",
            CompanyName:"Company Name",
            info:"Give us Little info about your Startup",
            attachment:"File Attachments",
            aggrement:"By clicking on 'Apply', You agree to and understand our <span><Link style={{color:'blue'}} to='/terms'>terms of use</Link ></span> & <span><Link style={{color:'blue'}} to='/privacy'>Privacy policy</Link></span>"
           },

         // infopage 
         infopage: {
            infoheader:"O<span>ur Pro</span>grams",
            infoheadermessage: "Our overall goal is to see your startup takeoff and to power founders to go further and faster. After you are family member, you'll basically be intensively productive hence, be in better position than before.",
            why:"What Happens At Kefeta",
            why2:"INTRODUTION",
            infofirstpar: 'The Kefeta Start-ups program is designed to accelerate the growth of early-stage service start-ups, providing a comprehensive support system that includes mentorship, funding, resources, and networking opportunities. Here is an overview of what happens during the Kefeta program and the benefits you get as a Kefeta founder.',
            info2par:"<b>THE KEFETA START-UPS ACCELERATOR PROGRAM</b> ",
            info3par: 'Kefeta hosts two 3-month programs — one from September through December, and one from February through April. Here’s what happens during the 3 months of Kefeta:',
            info4par:"<b>The Goal</b>",
            info5par:"The overall goal of Kefeta is to help start-ups really take off. Start-ups arrive at Kefeta at all different stages. Some haven’t even started working yet, and others have been launched for a year or more. But whatever stage a startup is at when they arrive, our goal is to help them to be in dramatically better shape 3 months later.",
            info6par:"For most startups, better shape translates into two things: to have a better product with more users, and to have more options for raising money. For 3 months, it’s all startup, all the time. Everyone around you—us, the other founders in your batch, the alumni, the speakers, the investors—wants to help your startup succeed. In that atmosphere it’s hard not to be highly motivated. And that kind of extraordinary motivation is what one needs to do something as difficult as starting a startup. Kefeta continues after the 3 month cycle, and the alumni community is an increasingly valuable resource, those 11 weeks are still the most important thing. You can’t make people something they’re not, but the right conditions can bring out the best in them. And since most people have way more potential than they realize, they’re often surprised by what they’re capable of achieving. ",
            info7par:"for additional information on why an accelerator is important, see this wonderful article on startup accelerators from <a href='https://hbr.org/2016/03/what-startup-accelerators-really-do' target='_blank' rel='noopener noreferrer' style={{color:'blue',textDecoration:'underline'}}>Harvard Business Review</a>.",
            Areas:"Batch Retreat",
            areapara:"In the first few weeks of the batch, we host a 1-day, in-person retreat. The retreat gives founders the opportunity to get to know each other, their group partners, and the Kefeta team.",
            Businessmodelstrategyhead:"Alumni Talks",
Leadershipteam:"Every Month, we invite an eminent person from the Business and Start-up world to speak. Most speakers are successful startup founders who tell the inside story of what happened in the early days of their startups. Talks are strictly off the record to encourage candor, because the inside story of most startups is more colorful than the one presented later to the public.",
Usercentereddesignhead:"Public Launches",
Technology:"Once a startup has something built that’s ready to launch, we help founders figure out how to present it to users and the press. We prepare founders for launches on community sites and their first press pitches and interviews.",
Compliancehead:"First Customers",
Relationalcapital:"B2B and consumer companies often get their first 10-15 paying customers from the Kefeta community. With that, you not only get first customers, but you also get the smartest early product feedback possible.",
Networkinghead :"Weekly Meet-ups",
Networking :"Throughout the batch, we host weekly meet-ups. These events often feature special guests.",
        
Networkinghead2 :"<b>Demo Day</b>",
Networking2 :"On Demo Day, the latest batch of Kefeta Start-up founders present their companies to an audience of specially selected investors and press.",
        
Networking3 :"In the weeks following Demo Day we keep in close touch with the start-ups as they negotiate the fundraising maze, and help them decipher the real messages in investors’ sometimes deliberately ambiguous responses. Often we talk to the investors ourselves, to find out what they’re really thinking about a particular start-up and their decisions.",
        
        },

        //login
        login:{
            Login:"Login",
            Email:"Email",
            Password:"Password",
            ForgotPassword:"Forgot Password?",
            Login2:"Login",

            ResetPassword:"Reset Password",
            Resetsuccessful:"Reset successful!",
            SetNewPassword:"Set New Password",
            Passwordlength:"Password must be at least 8 characters long",
            Errorresetting:"Error resetting password",
            Resetsucces:"Reset success! Click here",
            verificationsent:"A verification code has been sent to your email.",
            Submit:"Submit",
            Minuteremaining:"Minute remaining",
            SendResetCode:"Send Reset Code",
            Emailnotfound:"Email not found"
        },


        // userdash
        userdash:{
          clicktologin:"Please click here to login",
          Selam:"Selam",
            greetings:"Hope everything goes as planned in your journeys.",
            Aboutmodule:"About the module",
            onceapproved:"Once your account is approved, you will have access to all of your modules.",
            
            
            YourModules:"Your Modules",
            View:"View",
            Download:"Download",
            notactive:"Your account is not active",
            Logout:"Logout",
            Ok:"Ok",
            nodata:"no data",
            Notification:"Notification",
            
            sendmessage:"send message",
            Settings:"Settings",
            Cancel:"Cancel",
            contactanytime:"Feel free to contact us anytime",
            nomessage:"You have no message at the moment",
            onesec:"One sec...",
            Message:"Message",
            sendMessage:"Send Message",
            ChangeProfile:"Change Profile",
            PreviousPassword:"Previous Password",
            PreviousEmail:"Previous Email",
            NewPassword:"New Password",
            Loginseeupdate:"Login to see the update.",
            



        },
       
        },
      },



      am: {
        translation: {
 // navigaion
            homeNav: {
                offerService:"አገልግሎት እና አቅርቦት",  
                navabout: 'ስለኛ',
                navapply:"ለመመዝገብ", 
                blog:"ብሎጎች ", 
                info:"መረጃ ", 


         
              },
  //hero
              homeHero: {
                
                heroslogan:"ህልምዎትን ወደ",
                heroslogan2:"እውነታ",

                heromessage: 'ለስራ <b>ፈጣሪዎች የምክር እና የግብዓቶች </b>  አገልግሎት እናመቻቻለን፡፡',
               herobtn:"ለመመዝገብ", 
                feedbacktop:"የእርስዎ አስተያየት እኛን ይጠቅመናል።",        
                feedbackhead:"እባክዎን አስተያየትዎን ከዚህ በታች ያቅርቡልን",        
                feedbacksend:"ይላክ",        
                feedbackcancel:"ይቅር",  
                investors:"ለኢንቨስተሮች",        
                forinvestors:"ለኢንቨስተሮች",        
                         
              },
              //herocontact
          homecontact: {
            Contactus:"በእነዚህ አማራጭ",
            Through:"ያግኙን",
            EthiopiaAddisAbaba: 'ኢትዮጵያ፣ አዲስ አበባ',
               
          },
               // about home 
            homeabout: {
               
          aboutHead: 'ክፍታ ምንድን ነው',
          aboutHead2: 'ስለ ስራችን ',
          aboutMessege1:"በኢትዮጵያ ተመስርተን፣ ከፍታ አዲስ ስራ ፈጣሪዎችን ለመደገ የተነሳሳ ሲሆን፣ ሃሳብዎትን በማሳደግ ፣ የንግድ ሞዴሎን በማሻሻል እና የስኬት መንገድዎን መማፍጠን በዚህ የተጨናነቀ የንግድ ነገር ሁኔታ ከተወዳዳሪዎች ለየት ባለ ሁኔታ እንዲታዩ እንረዳዎታለን።።",
          aboutMessege2:"<strong> የምክር አገልግሎት:</strong>ከኢንደስትሪ መሪዎች፣ ልምድ የቀሰሙ አንጋፋ ስራ ፈጣሪዎች እና የሴክተር ባለሙያዎች ጋር ግንኙነቶችን መፍጠር።.</br> <strong>አጋርነት: </strong>ተመሳሳይ አስተሳብ ካላቸው ስራ ፈጣሪዎች ጋር ማህበረሰብ ይቀላቀሉ እና ልምድ ይለዋወጡ።"
          ,aboutButton:"ሙሉ መረጃ",
          roadHeading:"ከዚህ በሃላ<span style={{color:'#fd8349'}}> የኛ ቤተሰብ </span>ናቹ!",
          ServiceAndOffers:"አገልግሎት እና አቅርቦት",
          Providetoolsfor:"የኛ አገልግሎቶች",
          Startups:"<span>ለስራ ከጣሪዎች</span>",
        },

         // investors
         investors: {
          FORINVESTORS:"ለባለሀብቶች",
          Accesshig: 'ከፍተኛ አቅም ያላቸውን ጅምር ሃሳቦችን መድረስ የሚያስችል፡፡',
          firstText:'በጥንቃቄ የተመረጠ ፖርትፎሊዮ እና ከፍተኛ አቅም ያላቸዉ ጅምር ሃሳቦች ላይ አሻራዎትን ያሳርፋሉ፡፡ የእርስዎን የኢንቨስትመንት መስፈርት ለማሟላት በተዘጋጁ ልዩ የኢንቨስትመንት ዘርፎች ላይ ይሳተፉ።',
          StrategicNetworking: 'ስልታዊ ትስስር እና አጋርነት እድሎች',
          SECText:'በልዩ ሁኔታ በሚዘጋጁ ስብሰባዎቻችን እና የግንኙነት ዝግጅቶቻችን ላይ ከሌሎች ባለሀብቶች፣ የኢንዱስትሪ መሪዎች እና የሃሳብ ባለቤቶች ጋር ይተዋወቁ',
          DueDiligencesupport:"ተገቢ ድጋፍ",
          ThiredText:'በውሳኔ አሰጣጥ ሂደትዎ ውስጥ እገዛን ለመስጠት አጋዥ ሊሆኑ የሚችሉ የኢንቨስትመንት መገለጫዎችን እና የአፈጻጸም መለኪያዎችን ይቀበሉ። በመረጃ የተደገፈ የኢንቨስትመንት ውሳኔዎችን ለማድረግ ሁሉንም አስፈላጊ መረጃዎች እንዳሎት በማረጋገጥ የባለሙያ ቡድናችንን በትክክለኛ ትጋት ሂደት ለመርዳት ይጠቀሙ።',
          WHYPARTNER: 'ከእኛ ጋር የመተባበር ጥቅሞች?',
          ExtensiveNetwork:"ሰፊ አውታረ መረብ",
          FourthText:'ሰፊ የኢንቨስትመንት ዕድሎችን ለማግኘት የእኛን ዓለም አቀፍ አጋሮች፣ አማካሪዎች እና የቀድሞ ተማሪዎች አውታረ መረብ ይንኩ። ፈጠራን እና ትብብርን በማጎልበት ከተለያዩ እና ከስራ ፈጣሪዎች እና ከሌሎች ባለሀብቶች ማህበረሰብ ጋር ይሳተፉ።',
          ProvenRecord: 'የተረጋገጠ የትራክ መዝገብ',
          FivethText:'በስኬት እና በኢንቨስትመንት ላይ ከፍተኛ ትርፍ ካገኘን ሪከርዳችን ተጠቃሚ ይሁኑ። ሊለካ የሚችል ተፅእኖ እና የረጅም ጊዜ ስኬት ቅድሚያ ከሚሰጥ የፍጥነት ፕሮግራም ጋር አጋር ሁኑ።',
          asidehead: 'ተጨማሪ መረጃ',
          asidelink1: 'ስለኛ',
          asidelink2: 'ብሎጎች እና ዜናዎች',
          },

        // step home 
        homestep: {
            stepHeading:"እንዴ<span style={{paddingBottom:'3px',borderBottom:'1px solid black'}}>ት መሳተፍ ይ</span>ችላሉ",
            stepHead1: 'የገንዘብ ድጋፍ',
            stepMessege1:"ነገሮችን ለመጀመር እና እንደ አስፈላጊነቱ ለማደግ እስከ 10,000 ብር የገንዘብ እርዳታን እናቀርባለን። ለተሳታፊዎች የሚሰጠው ገንዘብ ለመጀመሪያ ዙር ተወዳዳሪዎች እና በሂደት እንደሚጨምር እንገልጽለን።",
            stepHead2: 'የቢሮ ቦታ',
            stepMessege2:"አስፈላጊ ከሆነ የቢሮ ቦታን እንዲሁም አገልግሎት አቅራቢዎችን ለማግኘት እና ለመምረጥ እገዛ እናደርጋለን፡፡ በተጨማሪም ከተባባሪዎች እና አጋር ከድርጅቶች ጋር ዘላቂ ግንኙነቶች እንዲመሰርቱ እንረዳዎታለን፡፡",
            stepHead3: 'አማካሪ እና መስራቾች',
            stepMessege3:"አማካሪ እና መስራቾች ጋር የሚኖር ግኑኝነት፤ እያደገ የመጣውን የበቁ አማካሪዎቻችንን እና ስራ ፈጣሪ ኔትወርክአችንን ይቀላቀሉ",
           },
        // footer
        footer: {
            about:"ስለኛ", 
            aboutus: "ስለኛ በጥቂቱ",
            apply:"ለመመዝገብ",
            Contact: "እኛን ለማናገር",
            Email:"ኢሜል",
            Phone: 'ስልክ ቁጥር',
            TERMSOFSERVICE:"የአገልግሎት ውል",
            TERMS:"ውል",
            PRIVACY:"መረጃ አጠባበቅ",
            Language:"ቋንቋ",
            rights:"መብቱ በህግ የተጠበቀ ነው",
            rights2:"ከፍታ",
             info:"መረጃ"
           },
           

           // userdash
        userdash:{
          clicktologin:"ለመግባት ይህን ይንኩ",
          Selam:"ሰላም",
            greetings:"ጉዞዎ እንደታቀደው የተሳካ እንደሚሆን እንነመኛለን",
            Aboutmodule:"ስለ ሞጁሉ",
            onceapproved:"ማነንቶን ማረጋገጥ በሃላ፣ ሁሉንም ሞጁሎች እዚው ማየት ይችላሉ።",
           
            
            YourModules:"የእርስዎ ሞጁሎች",
            View:"ለማየት",
            Download:"ለማውረድ",
            notactive:"ማንነቶ አልተረጋገጠም!",
            Logout:"ለመውጣት",
            Ok:"እሺ",
            nodata:"አዲስ መረጃ የለም",
            Notification:"ማስታወቂያ",         
            sendmessage:"መልዕክት ለመላክ",
            Settings:"አካውንት ለማስተካከል",
            Cancel:"ይቅር",
            contactanytime:"በየትኛውም ሰዐት ማናገር ይችላሉ፡፡",
            nomessage:"አዲስ መልእክት የለም",
            onesec:"አንዴ ይጠብቁን...",
            Message:"ሜሴጅ",
            sendMessage:"ይላክ",

            ChangeProfile:"አካውንት ለማስተካከል",
            PreviousPassword:"የበፊት ፓስዎርድዎን ይስገቡ",
            PreviousEmail:"የበፊት ኢሜል ይስገቡ",
            NewPassword:"አዲስ ፓስዎርድ ይስገቡ",
            Loginseeupdate:"አዲሱን ለውጥ ለማየት ይህን ንኩ",
        },


 // apply
 apply: {
  noteHead:"የተወሰኑ ቃላቶች",
  notes1: "አብርችሁን ስትሰሩ፤ በጉዞህ በምታገኙት ጉልህ ማሻሻያዎች እና ጠቃሚ እውቀቶች ደስትኛ ትሆናላቹ።",
  notes2: "በእድገትዎ እና በስኬቶችዎ ላይ በማሰላሰል፣ በግል እና በሙያዊ ያደረጓቸውን አስደናቂ እመርታዎች ይገነዘባሉ።",
  notes3: "እያንዳንዱ እርምጃ ወደፊት እና እያንዳንዱ ፈተና ለቀጣይ እድገትዎ እና ውሎ አድሮ ለስኬትዎ አስተዋፅዖ ያደርጋል። መንገዳቹ ከራሳቹ ግምት በላይ የማለፍ አቅም አለው።",
  apply:"ይላክ",
  
  Phone: "ስልክ",
  CustomerSupport: 'የደንበኛ አገልግሎት',
  FirstName:"ስም",
  LastName:"የአባት ስም",
  CompanyName:"የድርጅት ስም",
  info:"ስለ ስራዎት ትንሽ መረጃ ይስጡን",
  attachment:"ፋይል ያስቀምጡ",
  aggrement:"'ይላክ' የሚለውን በመንካት፣<span><Link style={{color:'blue'}} to='/terms'>የአጠቃቀም ውላችንን</Link ></span> እና <span><Link style={{color:'blue'}} to='/privacy'>የግላዊነት ፖሊሲያችንን</Link>ተረድትው ተስማምተዋል</span>"
 },
   
 
 // infopage 
 infopage: {
    infoheader:"ስ<span>ለ እኛ ፕሮ</span>ግራሞች",
    infoheadermessage: "ግባችን ጅምር ሀሳቦን ተሳክቶ ማየት እና መስራቾች በበለጠ እና በፍጥነት ወደ ግባቸው እንዲሄዱ ማበረታታት ነው። አብረውን መስራት ከጀመሩ ብውሀላ የከፍታ ቤተሰብኞት፣ የከፍታ ቤተሰብ ሲሆኑ እርስዎ በመሠረቱ በጣም ውጤታማ ይሆናሉ፣ ከበፊቱም በተሻለ ሁኔታ ላይ ይሆናሉ፡፡ ",
    // why:"ፕሮግራሙ ለምን አስፈለገ?",
    why:"What Happens at Kefeta",
    why2:"መግቢያ",
    infofirstpar: 'የከፍታ ጅምር ኘሮግራም የመጀመሪያ ደረጃ አገልግሎት ጅማሪዎችን እድገት ለማፋጠን የተነደፈ ሲሆን አጠቃላይ የአማካሪነት ፣ የገንዘብ ድጋፍ ፣ ግብዓቶች እና የግንኙነት እድሎችን ያካተተ ነው። በ ከፍታ ፕሮግራም ወቅት ምን እንደሚከሰት እና እንደ መስራች የሚያገኙትን ጥቅም በአጭሩ እነሆ።',
    info2par:"<b>የከፍታ START-UPS አክስሌራተር ፕሮግራም</b> ",
    info3par: 'ከፍታ ሁለት ጊዜ የ3 ወራት ፕሮግራሞችን ያስተናግዳል - አንደኛው ከመስከረም እስከ ታኅሣሥ፣ ሁለተኛ ከየካቲት እስከ ሚያዝያ። በከፈታ በ3 ወራት ውስጥ የሚከናወኑት እነዚህ ናቸው፡-',
    info4par:"<b>ግቡ</b>",
    info5par:"የከፈታ አጠቃላይ ግብ ጀማሪዎች በእውነት እንዲነሱ መርዳት ነው። ጀማሪዎች በየደረጃው ቀፈታ ይደርሳሉ። አንዳንዶቹ ገና መሥራት እንኳን አልጀመሩም, እና ሌሎች ለአንድ አመት ወይም ከዚያ በላይ ተጀምረዋል. ነገር ግን ጅምር ሲደርሱ በየትኛውም ደረጃ ላይ ቢገኙ ግባችን ከ3 ወራት በኋላ በአስደናቂ ሁኔታ የተሻለ ቅርፅ እንዲኖራቸው መርዳት ነው። ",
    info6par:"ለአብዛኛዎቹ ጀማሪዎች የተሻለ ቅርፅ ወደ ሁለት ነገሮች ይተረጎማል፡ ከተጠቃሚዎች ጋር የተሻለ ምርት ለማግኘት እና ገንዘብ ለማሰባሰብ ብዙ አማራጮችን ማግኘት። ለ 3 ወራት, ሁሉም ጅምር ነው, ሁል ጊዜ. በአካባቢዎ ያሉ ሰዎች ሁሉ—እኛ፣ በቡድንዎ ውስጥ ያሉ ሌሎች መስራቾች፣ ተማሪዎች፣ ተናጋሪዎች፣ ባለሀብቶች—ጅምርዎ ስኬታማ እንዲሆን መርዳት እንፈልጋለን። በዚያ ከባቢ አየር ውስጥ ከፍተኛ ተነሳሽነት ላለማድረግ ከባድ ነው። እና እንደዚህ አይነት ያልተለመደ ተነሳሽነት አንድ ሰው ጅምርን እንደ መጀመር አስቸጋሪ የሆነ ነገር ማድረግ ያስፈልገዋል. ክፈታ ከ 3 ወር ዑደት በኋላ ይቀጥላል, እና የተመራቂዎች ማህበረሰብ ከጊዜ ወደ ጊዜ እየጨመረ የሚሄደው ዋጋ ያለው ሃብት ነው, እነዚያ 11 ሳምንታት አሁንም በጣም አስፈላጊው ነገር ናቸው. ሰዎች ያልሆኑትን ማድረግ አይችሉም, ነገር ግን ትክክለኛዎቹ ሁኔታዎች በእነሱ ውስጥ ምርጡን ማምጣት ይችላሉ. እና ብዙ ሰዎች ከሚያውቁት በላይ አቅም ስላላቸው፣ ብዙ ጊዜ ማሳካት በሚችሉት ነገር ይገረማሉ።",
    info7par:"ስለ አክስሌሬተር አስፈላጊነት ተጨማሪ መረጃ ከሃርቫርድ ቢዝነስ ሪቪው ይመልከቱ <a href='https://hbr.org/2016/03/what-startup-accelerators-really-do' target='_blank' rel='noopener noreferrer' style={{color:'blue',textDecoration:'underline'}}>Harvard Business Review</a>.",
    Areas:"መተዋወቅ",
    areapara:"በቡድን የመጀመሪያዎቹ ሳምንታት ውስጥ የ1-ቀን በአካል ፕሮግራም እናስተናግዳለን። ፕሮግራም መስራቾች፣ የቡድን አጋሮቻቸው እና የከፈታ ቡድን እንዲተዋወቁ እድል ይሰጣል።",
    Businessmodelstrategyhead:"የተመራቂዎች ንግግሮች",
Leadershipteam:"በየወሩ፣ ከቢዝነስ እና ጀማሪ አለም አንድ ታዋቂ ሰው እንዲናገር እንጋብዛለን። አብዛኞቹ ተናጋሪዎች በጅምር ጅምር መጀመሪያ ላይ የሆነውን የውስጥ ታሪክ የሚናገሩ የተዋጣላቸው ጀማሪ መስራቾች ናቸው። ግልጽነትን ለማበረታታት ንግግሮች ከመዝገብ ውጪ ናቸው፣ ምክንያቱም የአብዛኞቹ ጀማሪዎች ውስጣዊ ታሪክ በኋላ ለህዝብ ከቀረበው የበለጠ በቀለማት ያሸበረቀ ነው።",
Usercentereddesignhead:"ይፋዊ ጅምሮች",
Technology:"አንድ ጅምር ለመገንባት ዝግጁ የሆነ ነገር ካለው፣ መስራቾች እንዴት ለተጠቃሚዎች እና ለፕሬስ እንደሚያቀርቡ እንዲያውቁ እንረዳቸዋለን። በማህበረሰብ ድረ-ገጾች ላይ እና ለመጀመሪያ ጊዜ ጋዜጣዊ መግለጫዎቻቸው እና ቃለ-መጠይቆቻቸው መስራቾችን እናዘጋጃለን።",
Compliancehead:"የመጀመሪያ ደንበኞች",
Relationalcapital:"B2B እና የደንበኞች ኩባንያዎች የመጀመሪያ 10-15 ተከፋይ ደንበኞቻቸውን ከከፈታ ማህበረሰብ ያገኛሉ። በዚህ አማካኝነት የመጀመሪያ ደንበኞችን ብቻ ሳይሆን የሚቻለውን በጣም ብልጥ የሆነ ቀደምት የምርት አስተያየት ያገኛሉ።",
Networkinghead :"ሳምንታዊ ስብሰባዎች",
Networking :"በጥቅሉ በሙሉ፣ ሳምንታዊ ስብሰባዎችን እናስተናግዳለን። እነዚህ ዝግጅቶች ብዙውን ጊዜ ልዩ እንግዶችን ያቀርባሉ።",

Networkinghead2 :"<b>የተመክሮ ቀን</b>",
Networking2 :"በተመክሮ ቀን ላይ የቅርብ ጊዜዎቹ የቀፈታ ጀማሪ መስራቾች ኩባንያቸውን በተለየ ለተመረጡ ባለሀብቶች ታዳሚ አቅርበው ጋዜጣዊ መግለጫ ሰጥተዋል።",

Networking3 :"የማሳያ ቀንን ተከትሎ በነበሩት ሳምንታት ውስጥ ጀማሪዎች የገንዘብ ማሰባሰብያውን ሲደራደሩ እና በባለሀብቶች አንዳንድ ጊዜ ሆን ተብሎ አሻሚ ምላሾች ውስጥ ያሉ እውነተኛ መልዕክቶችን እንዲፈቱ እናግዛቸዋለን። ስለ አንድ የተወሰነ ጅምር እና ውሳኔዎቻቸው ምን እንደሚያስቡ ለማወቅ ብዙውን ጊዜ ከባለሀብቶቹ ጋር እንነጋገራለን ።",

},
 //login
 login:{
    Login:"አካውንት",
    Email:"ኢሜል",
    Password:"ፓስወርድ",
    ForgotPassword:"ፓስወርድ ከረሳቹ?",
    Login2:"ግባ",

    ResetPassword:"አዲስ ፓስወርድ",
    Resetsuccessful:"ኮዱ ተረጋግጧል",
    SetNewPassword:"አዲስ ፓስወርድ ምረጡ",
    Passwordlength:"ፓስወርድ ቢያንስ 8 መሆን አለበት።",
    Errorresetting:"Error! ወደ አዲስ ፓስወርድ አልተቀየረም ",
    Resetsucces:"ፓስወርድ ወደ አዲስ ተቀይሯል! ይህን ተጫኑ",
    verificationsent:"የቁጥር ኮድ ወደ ኢሜልዎ ተልኳል፡፡ እሱን እዚህ ላይ ያስገቡ",
    Submit:"ይላክ",
    Minuteremaining:"ሚቀሮት ደቂቃ",
    SendResetCode:"ፓስወርድ ኮድ ወደ ኢሜል ይላክሎት",
    Emailnotfound:"ኢሜሉ አልተመዘገበም"
},
    },
      },
    },
  });

export default i18n;
