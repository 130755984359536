import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
// src/components/MainContent.js
import imgbg from '../../components/images/Newfolder/bgcontact.jpg'
import fb from '../../components/images/Newfolder/Facebook.png'
import insta from '../../components/images/Newfolder/Instagram-removebg-preview.png'
import twitter from '../../components/images/Newfolder/twitter.png'

import Footer from '../../components/footer/Footer'
import Nav from '../../components/nav/Nav'
import './BlogContent.css'
const BlogContent = () => (
    
    <>
    
    <Nav truth='yes'/>
    <div className='blog-postContent'>
    <Container className="blog-post">
      <Row className='rowBlogTopHead'>
        <Col md={8} className="mx-auto">
          <Card className="shadow-lg">
            <CardBody>
              <CardTitle className="text-center">
                <h1>What’s capital market in Ethiopia?</h1>
              </CardTitle>
             
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row style={{marginTop:"-28px"}} className="mt-5 rowBlogHeadquestion">
        <Col md={4} className="mx-auto">
          <Card className="shadow-lg shadow-lg2">
            <CardBody>
             
              <CardText className='blogCntentText'>
                <p>
                Capital Market, Capital Market, Capital Market we have heard about 
                this tide for the past year, it’s a trilling, gripping nuances of our time. 
                Despite Ethiopia’s revolutionary step towards a modernizing its financial sector, 
                it has yet to lay out the ground work.
</p>
<p>
Establishing the capital market is a better late than never approach, vital for our 
finance sector and inducive for entrepreneurs, investors and business owners alike. 
The benefits of the market is a no brainer but the question lays at what the Capital Market is 
for Ethiopia, what prospects it brings and over all the layout of the market for Ethiopians. This 
blog will delve into the topic, navigating the capital’s market dynamic nature. 
</p>
<p>
“Capital Market is crucial to strengthen Ethiopia’s investment activities and economic 
development creating opportunities for investors and companies to engage in a new way 
of investment to raise funds and encourage private investment. They added that domestic Capital
 Markets provide an alternative source of funding that can complement bank financing for a company
  in need of funding.” Quoted Dr. Brook Taye, the Director General of the Ethiopian Capital Market Authority.
   This suggests that capital markets can provide more competitive pricing, longer-term financing, and access
    to a broader range of investors. They can also support funding for higher-risk ventures that are typically not supported by the banking sector and play a significant role in driving innovation in an economy. A robust capital market would also enable governments to fund budget shortfalls without resorting to financial constraints or relying on foreign borrowing.
</p>
<p>
The capital market is the greatest news of our time to graze upon startups, business,
 entrepreneurs and the entire financial landscape of Ethiopia. The long bureaucratically 
 tedious bank loan procedural is not the only channel of finance but instead its replaced with
  an intuitive system that no only grants investment but also establish ownership of a company directly through stock exchange.
 </p>
 <p>
Even though the capital market in Ethiopia is in its infant stage it will take the financial sector by storm, take part in this revolutionary movement with Kefeta navigating at your side, elevating your dreams into reality! 

 </p>
              </CardText>
              
            </CardBody>
          </Card>
        </Col>
        
      </Row>

     
    </Container>
    <aside style={{ marginTop: "90px"}} className='blogContentAside'>
    <h3>working title</h3>
    <ul >
<li>All you need to know about capital market</li>
<li>What’s capital market in Ethiopia?</li>
<li>Navigating the capital market in Ethiopia</li>
<li> Capital market vs. Investment banks</li>
<li>What you need to know about the Ethiopian Capital market</li>
<li>Challenges of opening a new capital market in Ethiopia</li>
</ul>

<div className="blogContentSocialsList">
    <h3>Follow us</h3>
<div className="blogContentSocials">

<a href="https://www.facebook.com/profile.php?id=61558690284667"  target='_blank'><img src={fb} alt=''/></a>
<a href="https://twitter.com/KefetaStartup"  target='_blank'><img src={twitter} className='contact_icons' alt=''/></a>
<a href="https://www.instagram.com/kefeta_accele/" target='_blank' ><img src={insta} alt=''/></a>

</div>
</div>
    </aside>
    </div>
   <Footer/>
</>

);

export default BlogContent;