import { RiRefundFill } from "react-icons/ri";
import { FaSeedling } from "react-icons/fa6";
import { GrWorkshop } from "react-icons/gr";
import { MdOutlineGroups3 } from "react-icons/md";
import { SiFrontendmentor } from "react-icons/si"
import { GrBusinessService } from "react-icons/gr";
export default[
   
    { icon:MdOutlineGroups3,
    header:"ሁለንተናዊ የምክር አገልግሎት",
    text:"ካላቸው ስራ ፈጣሪዎች እናየኢንዱስትሪ ባለሙያዎች የማማከር ክፍለ ጊዜ ግንዛቤዎትን ያሳድጋሉ። የስኬት ጉዞዎን ከሀሳብ ወደ እውነታ የሚያሸጋግሩበትን እገዛ እና መመሪያን ይቀበላሉ።"

     },
    {  icon:RiRefundFill,
    header:"ስትራቴጂክ ባለሀብቶችን እና የገንዘብ ድጋፍ የሚያገኙባቸዉ ወርክሾፖች",
    text:"ከባለሀብቶች እና ከአጋር ድርጅቶች ጋር ትስስር ለመፍጠር የሚያስችሉ መድረኮች የሚዘጋጁበት ። ወሳኝ የገንዘብ ድጋፍ እንድታገኙ በተነደፉ የፒች ዝግጅቶች እና ልዩ አውደ ዉይይቶች ላይ ይሳተፋሉ።"

     },
     { 
        icon:FaSeedling,
header:"አጋዥ ትምህርቶች እና ስልጠናዎች",
text:"እኛ በምናዘጋቸዉ ክህሎት ተኮር ስልጠናዎች ራሶትን የሚያሳድጉበት መድረክ። ስራዎን ወደፊት ለማራመድ ከዎርክሾፖች፣ ሴሚናሮች እና ከብዙ ትምህርት ቁሳቁሶች ተጠቃሚ ይሚያደርጎት እድል ።"
     },
    { 
        icon:GrWorkshop,
header:"አጠቃላይ ድጋፍ",
text:"ሙሉ የድጋፍ አገልግሎቶችን እናቀርባለን ፣ማማከር እና ጠቃሚ የሆኑ የትስስር መድረኮች የሚፈጠሩበት።"
     },
   
    { 
        icon:GrBusinessService,
    header:"ከተያዩ የማህበረሰብ አካላት ጋር ልምድ ልዉዉጥ",
    text:"ከሁሉም አቅጣጫ እና የህይወት ዘርፎች የተውጣጡ ጀማሪ ባለሀሳቦች የሚቀበል ንቁ እና ሁሉን ያካተተ ማህበረሰብ እናሳድጋለን።"
     },
    { 
        icon:SiFrontendmentor,
    header:"ለዉጥ አምጪ ፕሮግራሞች"  ,
    text:"ፕሮግራሞቻችን ሊለኩ በሚችሉ ውጤቶች እና አዎንታዊ የህብረተሰብ ተፅእኖ ላይ በማተኮር የተነደፉ ናቸው።"
     },
]