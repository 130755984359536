import React,{useState,useRef,useEffect} from 'react'
import {Link} from 'react-router-dom'
import '../dashboard/Dashboard.css'
import '../../pages/itemlists.css'
import ApplicationsTable from '../../components/companyapplication_info/ApplicationsTable'
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { VscFeedback } from "react-icons/vsc";
import '../../components/sendmess/sendmess.css'
import Search from '../../components/search/Search'
import Nav from '../../components/navadmin/Nav'
import Sidebar from '../../components/sidebar/Sidebar'
import Customercount from '../../components/customercount/Customercount'
import feedbackImg from '../../components/images/Newfolder/feedback.png'
function Feedback_view() {
  
  /* month and day remaining calculator*/
  const [applications, setApplications] = useState([]);
  const [search, setSearch] = useState(applications);
  const [searchByChoice, setSearchByChoice] = useState('company_name');
   const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackView, setFeedbackView] = useState(false);






  const [emails, setEmails] = useState(['']); //to add email multiple times, remove double cot and add this line
  
    /* const handleAddEmail = () => {
    setEmails([...emails, '']);
  };*/


  const [messagehead, setMesshead] = useState('');
  const [message1, setMessage1] = useState('');
  const [successmessage2, setsucMessage] = useState('');

  
  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://api.kefetastartups.com/sendmessageRoutes/sendmessagetousers', {
        emails,
        message,
        messagehead,
      });
      console.log('Data submitted successfully');
      setsucMessage('Data submitted successfully');
    } catch (error) {
      console.error('Error submitting data:', error);
      setsucMessage('Error submitting data');
    }
  };




  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await fetch('https://api.kefetastartups.com/viewfeedback/getfeedbacks');
        if (response.ok) {
          const data = await response.json();
          setFeedbacks(data.feedbacks); // uncomment this line if you want to set state
        } else {
          console.error('Failed to fetch feedbacks');
        }
      } catch (error) {
        console.error('Error fetching feedbacks:', error);
      }
    };
  
    fetchFeedbacks();
  }, []);

 


      //pdf export
      const tableRef = useRef(null);

      const exportPDF = () => {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
      
        doc.setFontSize(15);
        const title = 'Table Information';
        const headers = [[ 'Id','Feedback']];
        let data = []
         data= feedbacks.map(items=>{
         return(
            [
              [ items.id],
                 [items.description]
                

                 
            ]
         )
        })
      
        let content = {
          startY: 50,
          head: headers,
          body: data,
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('table.pdf');
      };

      




      const closePopup = () => {
        setShowPopup(false); // Close the popup
        window.location.reload(false)
      };  

//seach type option

// const handleSearchByChange = (e) => {
//   setSearchByChoice(e.target.value);
// };

const filterby = (e) => {
  const search = e.target.value.toLowerCase();
  const filteredNames = feedbacks.filter(searchs => {
    const companyNameMatch = searchs.description.toLowerCase().includes(search);

    if (searchByChoice === "name" && companyNameMatch) {
      return true;
    } 

    // Add more conditions if needed for other search types
    return false;
  });

  setSearch(filteredNames);
};








//checkbox functionaliity to save filled extingusher
const [selectedRows, setSelectedRows] = useState([]);
const [showPopup, setShowPopup] = useState(false);



const closePopup2 = () => {
  setShowPopup(false); // Close the popup
  window.location.reload(false)
};

const toggleFeedback=()=>{
setFeedbackView(!feedbackView)
}


















  const filterName = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = feedbacks.filter(
      (searchs) => searchs.description.toLowerCase().includes(searchValue)
    );
    setSearch(filteredData);
  };


      const [visible, setVisible] = useState(false);
      const toggleRef = useRef(false);

    let backopacity=visible?"blurry":"visible";



      /* send message functionality */
      const [inputType, setInputType] = useState('text');

      const handleRadioChange = (event) => {
        setInputType(event.target.value === 'phone' ? 'text' : 'email');
      };


  return (
    <div className='dashboard' >
      <div className={`popup ${showPopup ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>Save successful!</p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>



      <div className={`popup ${showPopup ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>Save successful!</p>
          <button onClick={closePopup2}>Close</button>
        </div>
      </div>


      
<div className="dashboard_contents" >

<div className={`${backopacity}`}>
<Sidebar/>
     </div>
      <main className="dashboard-main main-section">
      <Nav/>
      

       <div className={`dashboard-main-shortcuts dashboard-main-shortcuts2 ${backopacity}`}>
      
        <h1 className={`${backopacity}`}>Send Message</h1>
        
        <ul>
  
        

        <Link to="">
      <Customercount/>
    </Link>
  
    <Link onClick={toggleFeedback} className='feedback_viewBox' to="">
    <li>
      <div>
        <h2>Feedback</h2>
        <p>See Feedback</p>
        </div>
        <img className='feedbackImage' src={feedbackImg} alt=''/>
       
</li>
</Link>

</ul>

        
       
       </div>

<div className='twodivs'>
 {/* table */}
 {feedbackView && 
 <div className="dash-tables dash-tables2" >
<div className="headAndsearch headAndsearch2">
{successMessage && <div className='success-messagee'>{successMessage}</div>}
<h4 className={`${backopacity}`}>Feedbacks</h4> 


          <div className="exportAndClose">
        <p onClick={exportPDF}>Export PDF</p>
        <p style={{backgroundColor:"rgb(189, 33, 33)"}} onClick={toggleFeedback}>Close</p>
          </div>


          
 
</div>


 <table className={`${backopacity}`}>


<tr>


<th>Feedback</th>
<th>Time</th>
          
            
</tr>
{feedbacks.map((item) => (
            <tr key={item.id}>
            
            <td >{item.description}</td>
            <td >{item.submitDate}</td>
             
            </tr>
))}
</table> 

</div> 
}




<div className="dash-tables send-messtable" >
<div className='topsendmess'>
<div className="headAndsearch">
{successmessage2 && <p className='messagesucc'>{successmessage2}</p>}

<td className='checkd-btn' style={{backgroundColor:"white"}}></td>
 
</div>

    
      <div className="inputForm-container">
     
        {emails.map((email, index) => (
          <div className="input-container" key={index}>
            <label className="label">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              className="input"
              
            />
          </div>
        ))}
        <button className="add-button">
          Add Email
        </button>


        <div className="input-container">
        <label className="label">Header</label>
        <input
          type="text"
          value={messagehead}
          onChange={(e) => setMesshead(e.target.value)}
          className="input"
        />
      </div>


        <div className="input-container">
        <label className="label">Message</label>
        <textarea
          type="text"
          value={message1}
          onChange={(e) => setMessage1(e.target.value)}
          className="messagebox"
        />
      </div>
      <div className="input-container">
      <button onClick={handleSubmit} className="sendmess-button">
        Submit
      </button>
      </div>
      </div>
    

</div>
<div className='sendFormbottom'>
.
</div>
</div>
</div>

      </main>
     
    
    </div>
    
    </div>
  )
}

export default Feedback_view;