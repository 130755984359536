import { useEffect } from "react";
import ReactGa from "react-ga4"


const GoogleAnalytics=()=>{
    useEffect(()=>{

        ReactGa.initialize("G-S6PZV2PFKZ");
    },[])

    return null
}


export default GoogleAnalytics;