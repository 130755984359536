
import React, { useState, useEffect } from 'react';


import About from '../components/about/Aboutcomp'
import Hero from '../components/hero/Hero.jsx'
import Road from '../components/road/Road.jsx'
import Step from '../components/step/Step'
import Contactus from '../components/contactus/Contactus'
import Footer from '../components/footer/Footer'
import { useNavigate } from 'react-router-dom';
import { MdOutlineConstruction } from "react-icons/md";
import './Home.css'
function Home() {
  const navigate=useNavigate();

  
  
  useEffect(()=>{
  const email=localStorage.getItem("email");
  if (email==='Kefetastartupaccelerator@gmail.com' || email==='kefetastartupaccelerator@gmail.com'){
    navigate('/dashboard');
   
  }
},[])
  

const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user is already logged in
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);


  return (
    <div className="home body" style={{width:"100vw", overflow:"hidden"}}>
       
  <Hero/>
  <About/>
  {/*
   <Road/>
  <Step/> 
*/}

  <Contactus/>
  <Footer/>

{/* <div class="containerConstruction">
        <MdOutlineConstruction class="construction-image" src="construction.png" alt="Under Construction"/>
        <h1 className="h1">Under Construction</h1>
        <p className="p">Sorry, this website is currently under construction.</p>
    </div>  */}
    </div>
  )
}

export default Home