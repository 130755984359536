import i18n from 'i18next'; // Assuming this is where your i18n setup is located
import { useState } from 'react';
import './LanguageSelector.css'
import { FaLanguage } from "react-icons/fa6";
function LanguageSelector() {
    const [langu, setlang]=useState(true)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setlang(!langu);
  };

  const changelangua=()=>{
setlang(!langu);
  }

  return (
    <div className='langaugesel'>
<h2 onClick={changelangua} > <FaLanguage/></h2>
      {!langu  && 
       <ul className='langaugesel_lang'>
       <p> Select Language</p>
        <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('am')}>አማርኛ</button>
      </div>
      <button className='lan_closebtn'onClick={changelangua}>Close</button>
      </ul>
}
    </div>
  );
}

export default LanguageSelector;
