import React from 'react'
import './Sidebar.css'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import { CiSettings } from "react-icons/ci";
import { IoIosSend } from "react-icons/io";
import { CiCirclePlus } from "react-icons/ci";
import {MdSpaceDashboard} from 'react-icons/md'
import { SiBaremetrics } from "react-icons/si";
import { VscFeedback } from "react-icons/vsc";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaExchangeAlt } from "react-icons/fa";
import img22 from '../images/Newfolder/bgsidebar.jpg'
import logo from '../images/Newfolder/dashicon-removebg-preview.png'

function Sidebar() {

  const [active,setactive]=useState("nav_menu");


    const [toggleIcon,setToggleIcon]=useState("nav_toggler")

    const navToggle=()=>{
        active==="nav_menu"? setactive("nav_menu nav_active"):setactive("nav_menu")
    toggleIcon==="nav_toggler"?setToggleIcon("nav_toggler toggle"):setToggleIcon("nav_toggler")
    }
    return (
      <div className='nav' style={{background:`url(${img22})`,backgroundPosition:'center',backgroundSize:"cover",backgroundRepeat:"no-repeat"}}> 
      <ul className={`dash_sidepart ${active}`} >
        
        <li className='listdashicon'>
          <Link to="/">
          <LuLayoutDashboard className='dashicon'/>
          </Link>
          </li>

<li>
  <Link to="/dashboard">
    <MdSpaceDashboard  className='sidebar-icons'  style={{color:"white"}}/>  
  <span>Dashboard</span>
  </Link>
  </li>
  
          <li>
            <a href="https://analytics.google.com/analytics/web/?authuser=1#/p436637714/reports/intelligenthome" target='_blank' rel="noopener noreferrer">
      <SiBaremetrics style={{color:"white"}}/> 
       <span>Seo Metrics</span>
       </a>
       </li>
       
            <li>
              <Link to="/usersmessage">
                <VscFeedback  className='sidebar-icons'  style={{color:"white"}}/> 
                 <span>Users message</span>
                 </Link></li>
            
            <li>
              <Link to="/feedbackview">
              <VscFeedback  className='sidebar-icons'  style={{color:"white"}}/> 
             <span>Send Message</span></Link></li>
  
          <li >
            <Link to="/changestatus">
              <FaExchangeAlt style={{color:"white"}}/>  
              <span>Change users status</span></Link></li>

          <li>
            <Link to="/settings">
              <CiSettings style={{color:"white"}}/>
            <span>Settings</span></Link></li>
          
</ul>
      
      <div onClick={navToggle} className={toggleIcon}>
                    <div className='line1'></div>
                    <div className='line2'></div>
                    <div className='line3'></div>

                </div>
      </div>
    );
  
}

export default Sidebar