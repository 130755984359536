export default [
    {
        id:1,
       termhead:"Categories of Personal Data We Process",
        termtext:["Audio/Visual Data: Recordings such as audio files and records (e.g., voice mails, call recordings, and the like).","Biographical Data: Data relating to professional, company name and similar biographic information.","Contact Data: Identity Data that includes personal and business contact information, such as phone number, email address or other identifiers used for communication.","Device/Network Data: Information regarding your interaction with a website, application, or advertisement (e.g., IP Address, MAC Address, SSIDs, etc.), online user ID, device characteristics (such as browser/OS version), web server logs, application logs, clear GIFs, pixel tags, and information about how you use our Site and interact with us.",""]
    },
    {
        id:2,
       termhead:" Sources of Personal Data We Process",
        termtext:["Data you provide us: We receive Personal Data when you provide them to us, when you fill forms or when you otherwise use our Services.","Data we collect automatically: We collect Personal Data about or generated by any device used to access our Service, e.g. IP addresses and similar Device/Network Data..","Aggregators and advertisers: We receive Personal Data from ad networks, data brokers, market research, or similar companies who provide us with additional Personal Data, e.g. Inference Data.",""]
    },
    {
        id:3,
       termhead:"Use of our Sites and Platform Generally",
        termtext:"We process Device/Network Data, Contact Data, Identity Data, and Inference Data when you access and use our Sites or our Platform. For example, we may log your computer or mobile device operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing to our Site, pages you viewed, how long you spent on a page, access times and information about your use of and actions on our Site. In some cases, we may collect Content, e.g. in the form of messages or content that you submit through our Sites and the Platform."
    },
    {
        id:4,
       termhead:"Contact us; support",
        termtext:" We process Identity Data, Contact Data, and Content when you contact us, e.g. through a contact us form, or for support. If you call us via phone, we may collect Audio/Visual data from the call recording (subject to any required consents). We process this Personal Data to respond to your request, and communicate with you, as appropriate, and for our Business Purposes. If you consent or if permitted by law, we may use Identity Data and Contact Data to send you marketing communications and for our Commercial Purposes. We do not sell or “share” Personal Data collected in this context."
    },
    {
        id:5,
       termhead:"Feedback and surveys",
        termtext:" We process Identity Data, Contact Data, Preference Data, and Content collected in connection with Event or partner surveys or questionnaires. We process this Personal Data as necessary to respond to guest requests/concerns, for our Business Purposes, and other legitimate interests"
    },
    {
        id:6,
       termhead:"Email communications.",
        termtext:"You may opt out of newsletters and marketing-related emails from us by following the unsubscribe instructions, You may continue to receive transactional and other non-marketing emails. If you authorized the sharing of your contact information with a third party, contact that third party directly to opt out of receiving any communications they may send."
    }
    
]