import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import './Aboutcomp.css'
import img1 from '../images/Newfolder/Businessteamworkingfeaturing.jpg'
import img2 from '../images/Newfolder/young guy using computer.jfif'
import wigly from '../images/Newfolder/gray_wiggly_flower_shape-removebg-preview.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import ReactGA from "react-ga4"
import { useTranslation } from 'react-i18next'


function Aboutcomp() {
    useEffect(()=>{
        AOS.init();
        AOS.refresh();
        },[]);


         useEffect(()=>{
         ReactGA.event({
      category: 'track page view',
      action: 'Clicked aboutus page',
      label: 'track number of users navigating to aboutus page',
    }); 
},[]);

const {t}=useTranslation();



  return (
    <div className="aboutcomp" id="componentAbout">
<div className="about-top">
<div className="about-wiggle">
<img src={wigly} alt='for decoration' />
</div>
    <section className="about-top_text" data-aos="fade-right" data-aos-duration="1800">
<h1>{t("homeabout.aboutHead")}</h1>
<p >{t("homeabout.aboutMessege1")} </p><Link style={{ zIndex: "44"}}  to="/info" className="btn about-btn"> {t("homeabout.aboutButton")}</Link>
    </section>
    <img src={img1} alt='three african business workers discussing. two girls and one man'/>
</div>


<div className="about-bottom">
<img className="aboutImage2" src={img2} alt='a young entuastic youth working on his laptop'/>
    <section className="about-bottom_text aboutsecText" data-aos="fade-left" data-aos-duration="1800">
<h1>{t("homeabout.aboutHead2")}</h1>
<p dangerouslySetInnerHTML={{ __html: t('homeabout.aboutMessege2') }}/>
<Link  style={{ zIndex: "444"}} to="/info" className="btn about-btn"> {t("homeabout.aboutButton")}</Link>
</section>
</div>


    </div>
  )
}

export default Aboutcomp