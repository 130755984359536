import React,{useState,useRef,useEffect} from 'react'
import {Link} from 'react-router-dom'
import '../dashboard/Dashboard.css'
import '../../pages/itemlists.css'
import ApplicationsTable from '../../components/companyapplication_info/ApplicationsTable'
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import messageicon from '../../components/images/Newfolder/messageicon.png'
import Search from '../../components/search/Search'
import Nav from '../../components/navadmin/Nav'
import Sidebar from '../../components/sidebar/Sidebar'
import Customercount from '../../components/customercount/Customercount'

function SeeUsersmessage() {
  
  /* month and day remaining calculator*/
  const [applications, setApplications] = useState([]);
  const [search, setSearch] = useState(applications);
  const [searchByChoice, setSearchByChoice] = useState('company_name');
   const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);

 

  useEffect(() => {
    // Fetch data from the server when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.kefetastartups.com/viewusersmessageroute/viewusersmessage'); // Adjust the endpoint URL
        setApplications(response.data.usersmessage);
        //console.log(applications); 
        //setSearch(response.data.usersmessage);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount





      //pdf export
      const tableRef = useRef(null);




  

//seach type option

// const handleSearchByChange = (e) => {
//   setSearchByChoice(e.target.value);
// };

const filterby = (e) => {
  const search = e.target.value.toLowerCase();
  const filteredNames = applications.filter(searchs => {
    const companyNameMatch = searchs.f_name.toLowerCase().includes(search);

    if (searchByChoice === "name" && companyNameMatch) {
      return true;
    } 

    // Add more conditions if needed for other search types
    return false;
  });

  setSearch(filteredNames);
};








//checkbox functionaliity to save filled extingusher
const [selectedRows, setSelectedRows] = useState([]);
const [showPopup, setShowPopup] = useState(false);



const closePopup = () => {
  setShowPopup(false); // Close the popup
  window.location.reload(false)
};



















  const filterName = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = applications.filter(
      (searchs) => searchs.f_name.toLowerCase().includes(searchValue)
    );
    setSearch(filteredData);
  };


      const [visible, setVisible] = useState(false);
      const toggleRef = useRef(false);

    let backopacity=visible?"blurry":"visible";



      /* send message functionality */
      const [inputType, setInputType] = useState('text');

      const handleRadioChange = (event) => {
        setInputType(event.target.value === 'phone' ? 'text' : 'email');
      };


  const exportPDFAndExcel = () => {
    const exportFormat = prompt("Choose export format: 'pdf' or 'excel'");
    if (exportFormat === 'pdf') {
      exportPDF();
    } else if (exportFormat === 'excel') {
      exportExcel();
    } else {
      alert("Invalid export format! Please choose 'pdf' or 'excel'.");
    }
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
  
    doc.setFontSize(15);
    const title = 'Users Information';
    const headers = [[ 'Name','Email','Company name','Number', 'Description','Attachment']];
    let data = []
     data= search.map(items=>{
     return(
        [
          [[ items.f_name ] +" "+[items.lastname]],
             [items.email],
             [items.company_name ],
             [items.phone_number ],
             [items.description ],
             [items.file_path ],

             
        ]
     )
    })
  
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('table.pdf');
  };

  
  const exportExcel = () => {
    const headers = ['Name', 'Email', 'Company name', 'Number', 'Description', 'Attachment'];
    let data = search.map((item) => [
      item.f_name + ' ' + item.lastname,
      item.email,
      item.company_name,
      item.phone_number,
      item.description,
      item.file_path
    ]);

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table.xlsx');
  };
const [isMesgAvtivate, setisMesgAvtivate] =useState(false);
const [username, setusername] =useState('');
const [Mesg, setMesg] =useState('');

 const activateMessage=(message,name)=>{
  setisMesgAvtivate(true);
  setMesg(message)
  setMesg(message)
  setusername(name)
 }


 const closeMessage=()=>{
  setisMesgAvtivate(false);
 }

  return (
    <div className='dashboard' >
      <div className={`popup ${showPopup ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>Save successful!</p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>
<div className="dashboard_contents" >

<div className={`${backopacity}`}>
<Sidebar/>
     </div>
      <main className="dashboard-main main-section">
      <Nav/>
      

       <div className={`dashboard-main-shortcuts ${backopacity}`}>
      
        <h1 className={`${backopacity}`}>Message</h1>
        
        <ul>
  
        <Link to="">
      <Customercount/>
    </Link>
  
</ul>

        
       
       </div>


 {/* table */}
<div className="dash-tables" >
<div className="headAndsearch">
{successMessage && <div className='success-messagee'>{successMessage}</div>}
<h3 className={`${backopacity}`}>See users message</h3> 

{/* <div style={{visibility:'hidden'}}>
            <label htmlFor="searchByChoice">Search By:</label>
            <input type="text" value="Company_name"
              id="searchByChoice"
              name="searchByChoice"
              onChange={filterby}
            />
              
          </div> */}
         
       

          
<td className='checkd-btn' style={{backgroundColor:"white"}}></td>
 
</div>

<table className={`${backopacity}`}>

<tr>

<th>Name</th>
            <th>Email</th>
            <th>Company Name</th>
            <th>Phone Number</th>
            <th>Message</th>
            <th>Time</th>
</tr>
{applications.map((item) => (
            <tr key={item.id}>
            
            <td>{item.f_name} {item.l_name}</td>
              <td>{item.email}</td>
              <td>{item.comapnyName}</td>
              <td>{item.number}</td>
              <td className='seemsgbtn1'  onClick={() => activateMessage(item.message,item.f_name)} ><p className='seemsgbtn' >Display</p></td>
              <td >{item.submitDate}</td>
            </tr>
          ))}
</table>
        
</div>
{
  isMesgAvtivate &&

  <div className="isMesgAvtivatemessg" >
    
    <div>
<h3>{username}'s  Message</h3>
<hr/>
<p>{Mesg}</p>
</div>
<button onClick={() => closeMessage()}>Close</button>

  </div>
}

      </main>
     
    
    </div>
    
    </div>
  )
}

export default SeeUsersmessage;