
import React, { useState,useEffect,useRef } from 'react';
import {useNavigate,Link, Navigate} from 'react-router-dom';
import './usersend.css'
import '../userdashboard/userdash.css'
import Nav from '../../components/nav/Nav';
import { FaArrowLeftLong } from "react-icons/fa6";
import { RiMessage2Fill } from "react-icons/ri";
import img13 from '../../components/images/Newfolder/goodd-removebg-preview.png';
import { IoIosNotifications } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import img12 from '../../components/images/Newfolder/user3d-removebg-preview.png';
import { FaUser } from "react-icons/fa6";
import {MdSpaceDashboard} from 'react-icons/md'
import axios from 'axios';
import { LiaTruckLoadingSolid } from "react-icons/lia";
import { useTranslation } from 'react-i18next'


const Usersendmessagetous = () => {


  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageerr, seterr] = useState(false);


   
    const toggleRef = useRef(false);
    const [visible, setVisible] = useState(false);

    
    const handleClick = () => {
      toggleRef.current = !toggleRef.current;
      setVisible(toggleRef.current);
    };
    
    const {t,i18n}=useTranslation(); 
    
    const userSessionEmail = sessionStorage.getItem('email');
    const sessionFirstName = sessionStorage.getItem('FirstName');
    const sessionLastName = sessionStorage.getItem('LastName');
    const sessionphonenumber = sessionStorage.getItem('phonenumber');

    console.log("from dash",sessionFirstName);
    console.log("from dash",sessionphonenumber);
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false); // Close the popup
        window.location.reload(false)
      };  


    



  const [emails, setEmails] = useState(['']); //to add email multiple times, remove double cot and add this line
  
  /* const handleAddEmail = () => {
  setEmails([...emails, '']);
};*/


const [FirstName, setfname] = useState('');
const [LastName, setlname] = useState('');
const [PhoneNumber, setnumber] = useState('');
const [CompanyName, setcompany] = useState('');
const [Usermess, setusermess] = useState('');
const [successmessage2, setsucMessage] = useState('');


const handleEmailChange = (index, value) => {
  const updatedEmails = [...emails];
  updatedEmails[index] = value;
  setEmails(updatedEmails);
};
   

const handleClick2 = () => {
      
  window.location.href = '/usernotification';
};

const closepopfun=()=>{
  setsucMessage(false)
}
const handleSubmit = async () => {

    if(FirstName===''  || LastName==='' || emails==='' || Usermess===''){
        setsucMessage('Sorry! Make sure the inputs are correct');
    }
    else{
  try {
    setLoading(true);
    //await axios.post('https://kefetastartupdomain.com.kefetastartups.com:3001/fromusersmessage', {
      await axios.post('https://api.kefetastartups.com/fromusersmessage/sendmessagefromuser', {
    emails,
      FirstName,
LastName,
PhoneNumber,
CompanyName,
Usermess,
    });
    console.log('Message submitted successfully');
     seterr(true);
    setsucMessage('Message submitted successfully');
    console.log('messs:',successmessage2)
    setLoading(false);
  } catch (error) {
    console.error('Error submitting message:', error);
    setsucMessage('Error submitting message');
    setLoading(false);
   seterr(false)
  }
}};

const handleLogout = () => {
  // Clear the session
  sessionStorage.clear();
  localStorage.clear();
  // Redirect the user to the login page
  window.location.href = '/';
};


    
const FirstNamesession = sessionStorage.getItem('FirstName');
const LastNamesession = sessionStorage.getItem('LastName');
const phonenumber = sessionStorage.getItem('phonenumber');


    


    const [isHovered, setIsHovered] = useState(false);
    const cardRef = useRef(null);
  
    const handleMouseMove = (e) => {
      const cursorCircle = document.getElementById('cursor-circle');
      cursorCircle.style.left = `${e.pageX}px`;
      cursorCircle.style.top = `${e.pageY}px`;
  
      // Check if the cursor circle is hovering over the card
      const cardRect = cardRef.current.getBoundingClientRect();
      const isHovered = e.clientX >= cardRect.left && e.clientX <= cardRect.right &&
                        e.clientY >= cardRect.top && e.clientY <= cardRect.bottom;
      setIsHovered(isHovered);
    };

  
    return (
        <div  className={`userdash userdash_set `}>
          <div className='navuser'>
          <Nav truth="yes" />
          </div>

      <div className="glasseffect glasseffect_sendmessage" ref={cardRef} onMouseMove={handleMouseMove}>
        
      <div id="cursor-circle" className={`cursor-circle ${isHovered ? 'active' : ''}`}></div>
      {/* <div className="secret-text">
        Hover me to reveal the secret!
      </div> */}





<div className="userdash_main userdash_main_settings userdash_main_sendmess" style={{backgroundColor:"white"}}>
    <div className='backnavigationandhead'>
       <Link to='/userdash'><FaArrowLeftLong /></Link>
<h2>{t("userdash.sendMessage")} </h2>

{/* {successmessage2 && <div className='success-messagee'>{successmessage2}</div>} */}
</div>


 <form className='sendmess_fromusers_form' style={{backgroundColor:"white"}}>

<div>
<label className="label">{t("login.Email")}</label>
        {emails.map((email, index) => (
          <div key={index}>
            <input
              type="email"
              placeholder='use your login email'
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              className="input"
            />
      </div>
      ))}
      </div>
<div>
<label className="label">{t("apply.FirstName")}</label>
        <input
          type="text"
          value={FirstName}
          onChange={(e) => setfname(e.target.value)}
          className="input"
        />
      </div>


      <div>
        <label className="label">{t("apply.LastName")}</label>
        <input
          type="text"
          value={LastName}
          onChange={(e) => setlname(e.target.value)}
          className="input"
        />
      </div>

      <div>
        <label className="label">{t("apply.CompanyName")}<span style={{fontSize:"0.7rem"}}>{" ("}optional{")"}</span></label>
        <input
          type="text"
          value={CompanyName}
          onChange={(e) => setcompany(e.target.value)}
          className="input"
        />
      </div>

        <div>
         <label className="label">{t("apply.Phone")}</label>
        <input
          type="text"
          value={PhoneNumber}
          onChange={(e) => setnumber(e.target.value)}
          className="input"
        />
      </div>
        <div style={{display:"flex", flexDirection:"column"}}>
         <label className="label">{t("userdash.Message")}</label>
        <textarea
          type="text"
          value={Usermess}
          onChange={(e) => setusermess(e.target.value)}
          className="input"
          />
      </div>

        
      <div className="usersettingbutton">
      <input type='button' onClick={handleSubmit}  style={{backgroundColor:"blue",cursor:"pointer",width:"fit-content",color:"white",marginTop:"30px" }}  value={i18n.language==="en"?"Send":"ይላክ"} />
          {loading && <p style={{width:"170px",margin:"10px 0px 0 300px",color:"green"}}> {t("userdash.onesec")} </p>}
  


      {
      successmessage2 && 
      <div style={{background:"#ececec", color:"blue"}} className="usersetmessage">
      <p>{successmessage2}</p>
<p className='closebtn_usrdash' onClick={closepopfun}> {t("userdash.Cancel")} </p>
      </div>
      }
      </div>

      

</form>
{
visible &&<div className={`logout-cont ${toggleRef}`} style={{zIndex:"70"}}>
    <h2>{t("userdash.Logout")}</h2>
    <div>
    <button onClick={handleLogout}>
          {t("userdash.Ok")} 
      </button>
    <Link onClick={handleClick} to='/usersendmessagetous'>
           {t("userdash.Cancel")} 
      </Link>
      </div>
</div>
      }
</div>

<sidebar className='dash_side'>
<div className='dash_side_cont'>
<div className="dash_side_profile">
  <img src={img12} alt=""/>
  <h4>{FirstNamesession} {LastNamesession}</h4>

  <div className="dash_side_profile_info">

<p>{t("login.Email")}<span>{userSessionEmail}</span></p>
<p> {t("apply.Phone")}<span>{sessionphonenumber}</span></p>
  </div>


</div>


<ul className="dash_side_bottom dash_side_bottom_mess">
  
  <li>
  <Link to="/usernotification">
    <IoIosNotifications  className='dashuser-icons'  style={{color:"black"}}/> 
    
  <p>{t("userdash.Notification")}</p> </Link>
  </li>
   <li><Link to="/usersendmessagetous"><RiMessage2Fill  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.sendmessage")}</p> </Link></li>
  
  <li><Link to="/userssettings"><FaUser  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Settings")}</p> </Link></li>
  <li  onClick={handleClick}>  <Link to=""><AiOutlineLogout  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Logout")}</p> </Link></li>

</ul>

<div className='samp'>
  <img src={img13} alt=''/>
  <p>{t("userdash.contactanytime")}</p>


</div>

</div>
</sidebar>

      </div>
      </div>
    );
  };
  
  export default Usersendmessagetous;
  
