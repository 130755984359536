import React,{useState,useRef,useEffect} from 'react'
import {Link} from 'react-router-dom'
import '.././dashboard/Dashboard.css'
import '../../pages/itemlists.css'
import './changestatus.css'
import ApplicationsTable from '../../components/companyapplication_info/ApplicationsTable'
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Search from '../../components/search/Search'
import Nav from '../../components/navadmin/Nav'
import Sidebar from '../../components/sidebar/Sidebar'
import Customercount from '../../components/customercount/Customercount'

function Changestatus() {
  
  /* month and day remaining calculator*/
  const [applications, setApplications] = useState([]);
  const [search, setSearch] = useState(applications);
  const [searchByChoice, setSearchByChoice] = useState('company_name');
   const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);

 

  useEffect(() => {
    // Fetch data from the server when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.kefetastartups.com/applications/getapplications2');
        setApplications(response.data.application);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount
  



  const updateStatus = async (userId, newStatus) => {
    try {
      await axios.put(`https://api.kefetastartups.com/updatestat/users/${userId}/status`, { status: newStatus });
      // Assuming the API updates the status successfully without returning any data
      // You may want to handle any error responses from the server
      // If successful, update the status in the UI
      setApplications(applications.map(app => app.user_id === userId ? { ...app, status: newStatus } : app));
      setSuccessMessage('Status updated successfully');
      setMessage(''); // Clear any previous error message
      
    } catch (error) {
      console.error('Error updating status:', error);
      setMessage('woops! Error updating status.'); // Set error message
      setSuccessMessage('');
    }
  };
  

  






  

//seach type option

// const handleSearchByChange = (e) => {
//   setSearchByChoice(e.target.value);
// };

const filterby = (e) => {
  const search = e.target.value.toLowerCase();
  const filteredNames = applications.filter(searchs => {
    const companyNameMatch = searchs.f_name.toLowerCase().includes(search);

    if (searchByChoice === "name" && companyNameMatch) {
      return true;
    } 

    // Add more conditions if needed for other search types
    return false;
  });

  setSearch(filteredNames);
};








//checkbox functionaliity to save filled extingusher
const [selectedRows, setSelectedRows] = useState([]);
const [showPopup, setShowPopup] = useState(false);



const closePopup = () => {
  setShowPopup(false); // Close the popup
  window.location.reload();
};



















  const filterName = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = applications.filter(
      (searchs) => searchs.f_name.toLowerCase().includes(searchValue)
    );
    setSearch(filteredData);
  };


      const [visible, setVisible] = useState(false);
      const toggleRef = useRef(false);

    let backopacity=visible?"blurry":"visible";



      /* send message functionality */
      const [inputType, setInputType] = useState('text');

      const handleRadioChange = (event) => {
        setInputType(event.target.value === 'phone' ? 'text' : 'email');
      };

const funsetSuccessMessage=()=>{
  setSuccessMessage(null);
  //navigator.vibrate(200);
  window.location.reload();
}
  return (
    <div className='dashboard' >
      <div className={`popup ${showPopup ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>Save successful!</p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>
<div className="dashboard_contents" >

<div className={`${backopacity}`}>
<Sidebar/>
     </div>
      <main className="dashboard-main main-section">
      <Nav truth="yes"/>
      

       <div className={`dashboard-main-shortcuts ${backopacity}`}>
      
        <h1 className={`${backopacity}`}>User Status </h1>
        
        <ul>
  
        <Link to="">
      <Customercount/>
    </Link>
  
</ul>

        
       
       </div>


 {/* table */}
<div className="dash-tables dash-tablesalterstat" >
<div className="headAndsearch">

{successMessage && <div className='success-message-inalteruser'><p>{successMessage}</p>
<button style={{width:"80px"}} onClick={() => funsetSuccessMessage()}>Refresh</button>
</div>}
  





{message && <div className='error-message'>{message}</div>}

<h3 className={`${backopacity}`}>Startups Information</h3> 

{/* <div style={{visibility:'hidden'}}>
            <label htmlFor="searchByChoice">Search By:</label>
            <input type="text" value="Company_name"
              id="searchByChoice"
              name="searchByChoice"
              onChange={filterby}
            />
              
          </div> */}
         
         {/* { <Search
            handleclick={filterName}
            handleclicksearch={filterby}
          /> } */}

          
<td className='checkd-btn' style={{backgroundColor:"white"}}></td>
 
</div>

<table className={`${backopacity}`}>

<tr>

<th>Name</th>
            <th>Email</th>
            <th>Company Name</th>
            <th>Current status</th>
            <th>Change status</th>
           
            
</tr>
{applications.map((app) => (
            <tr key={app.id}>
            
            <td>{app.f_name} {app.lastname}</td>
              <td>{app.email}</td>
              <td>{app.company_name}</td>
              <td style={{color:`${app.userstatus==="pending"?"orange":app.userstatus==="accepted"?"green":"red"}`}}>{app.userstatus}</td>
              <td className='status-change'>
              <button className="status-button accept" onClick={() => updateStatus(app.user_id, 'accepted')}>Accept</button>
<button className="status-button reject" onClick={() => updateStatus(app.user_id, 'rejected')}>Reject</button>
<button className="status-button pending" onClick={() => updateStatus(app.user_id, 'pending')}>Pending</button>

              </td>
              
              
            </tr>
          ))}
</table>

</div>


      </main>
     
    
    </div>
    
    </div>
  )
}

export default Changestatus;