// Install required package: npm install axios

import React, { useState, useEffect ,useRef} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import '../userdashboard/userdash.css'
import { MdLocalPlay } from "react-icons/md";
import { RiMessage2Fill } from "react-icons/ri";
import Nav from '../../components/nav/Nav';
import img11 from '../../components/images/Newfolder/message2-removebg-preview.png';
import img13 from '../../components/images/Newfolder/goodd-removebg-preview.png';
import img12 from '../../components/images/Newfolder/user3d-removebg-preview.png';
import { IoIosNotifications } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import {MdSpaceDashboard} from 'react-icons/md'
import { AiOutlineLogout } from "react-icons/ai";
import { useTranslation } from 'react-i18next'


const Usernotification = () => {
    const [email, setEmail] = useState('');
    const [messages, setMessages] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState('');


     const {t,i18n}=useTranslation(); 



    const toggleRef = useRef(false);
    const [visible, setVisible] = useState(false);

    
    const handleClick = () => {
      toggleRef.current = !toggleRef.current;
      setVisible(toggleRef.current);
    };

    const handleLogout = () => {
      // Clear the session
      sessionStorage.clear();
      localStorage.clear();
      // Redirect the user to the login page
      window.location.href = '/';
    };


    const handleClick2 = () => {
      
      window.location.href = '/usernotification';
    };
    
    const userSessionEmail = sessionStorage.getItem('email');
    const FirstName = sessionStorage.getItem('FirstName');
    const LastName = sessionStorage.getItem('LastName');
    const phonenumber = sessionStorage.getItem('phonenumber');

    
    useEffect(() => {
      
        axios
        .post('https://api.kefetastartups.com/notifications', { email: userSessionEmail })
          .then((response) => {
            if (response.data && response.data.notifications) {
              const notificationsWithSeen = response.data.notifications.map(notification => ({
                ...notification,
                seen: false,
              }));
              setNotifications(notificationsWithSeen);
              
              // Save notifications to localStorage
              localStorage.setItem('notifications', JSON.stringify(notificationsWithSeen));
            } else {
              setError("Invalid response format");
            }
          })
          .catch((error) => {
            setError(error.response?.data?.error || 'An error occurred');
          });
      
    }, []);
    
    


    const [isHovered, setIsHovered] = useState(false);
    const cardRef = useRef(null);
  
    const handleMouseMove = (e) => {
      const cursorCircle = document.getElementById('cursor-circle');
      cursorCircle.style.left = `${e.pageX}px`;
      cursorCircle.style.top = `${e.pageY}px`;
  
      // Check if the cursor circle is hovering over the card
      const cardRect = cardRef.current.getBoundingClientRect();
      const isHovered = e.clientX >= cardRect.left && e.clientX <= cardRect.right &&
                        e.clientY >= cardRect.top && e.clientY <= cardRect.bottom;
      setIsHovered(isHovered);
    };

  
    return (
        <div className='userdash'>
          <div className='navuser'>
          <Nav truth="yes"/>
          </div>

      <div className="glasseffect" ref={cardRef} onMouseMove={handleMouseMove}>
        
      <div id="cursor-circle" className={`cursor-circle ${isHovered ? 'active' : ''}`}></div>
      {/* <div className="secret-text">
        Hover me to reveal the secret!
      </div> */}


<div className="userdash_main .userdash_main_notif">
<div className="userdash_main_top">
  <div className="userdash_main_top_welcome">
    <h2>{t("userdash.Selam")} {FirstName}!</h2>
    <p> {t("userdash.greetings")}</p>
  </div>

  
</div>

<div className="userdash_bottom">
<h3>{t("userdash.Notification")}</h3>



{notifications.length > 0 ? (
  <ul className="userdash_bottom_contents userdash_bottom_contents_notifications">
    {notifications.map((notification, index) => (
      <li key={index}>
        <img src={img11} alt=""/>
        <div className='userdash_message'>
        <h4 >{notification.message_head}</h4>
        <p >{notification.message}</p>
      
        </div>
        <div className="message_actionicons">
        <p >
          {t("userdash.Notification")}
        </p>
        </div>
      </li>
    ))}
  </ul>
) : (
  <p>  {t("userdash.Notification")} </p>
)}

{
visible &&<div className={`logout-cont ${toggleRef}`} style={{zIndex:"70"}}>
    <h2>{t("userdash.Logout")} </h2>
    <div>
    <button  onClick={handleLogout}>
        {t("userdash.Ok")} 
      </button>
    <Link onClick={handleClick2} to='/userdash'>
        {t("userdash.Cancel")} 
      </Link>
      </div>
</div>
      }

</div>
</div>

<aside className='dash_side'>
<div className='dash_side_cont'>
<div className="dash_side_profile">
  <img src={img12} alt=""/>
  <h4>{FirstName} {LastName}</h4>

  <div className="dash_side_profile_info">

<p>{t("login.Email")}<span>{userSessionEmail}</span></p>
<p>  {t("apply.Phone")}<span>{phonenumber}</span></p>
  </div>


</div>


<ul className="dash_side_bottom">
  
  <li>
  <Link to="/usernotification">
    <IoIosNotifications  className='dashuser-icons'  style={{color:"black"}}/> 
    
  <p> {t("userdash.Notification")}</p> </Link>
  </li><li><Link to="/usersendmessagetous"><RiMessage2Fill  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.sendmessage")}</p> </Link></li>
  
  <li><Link to="/userssettings"><FaUser  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Settings")}</p> </Link></li>
  <li  onClick={handleClick}>  <Link to=""><AiOutlineLogout  className='dashuser-icons'  style={{color:"black"}}/> <p>{t("userdash.Logout")}</p> </Link></li>

</ul>

<div className='samp'>
  <img src={img13} alt=''/>
  <p>{t("userdash.contactanytime")}</p>


</div>

</div>
</aside>

      </div>
      </div>
    );
  };
  
  export default Usernotification;
  


