import React,{useState,useRef} from 'react'
import {useTypewriter,Cursor} from 'react-simple-typewriter'
import {Link, Navigate,useNavigate} from 'react-router-dom'
import img1 from '../images/Newfolder/Group333.png'
import imgMobile from '../images/Newfolder/group334.png'
//import arrowDown from '../images/Newfolder/arrowim.png'
import { MdEmojiEmotions } from "react-icons/md";
import { GrContact } from "react-icons/gr";
import { useTranslation } from 'react-i18next'
import'./Hero.css'
import { FaBullseye } from 'react-icons/fa'
import Nav from '../nav/Nav'
import heroImg from '../images/Newfolder/working_result.png'
import arrowDown from '../images/Newfolder/arrowDownHero.png'

function Hero() {
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
 const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null
  );
  const closePopup = () => {
    setShowPopup(false); // Close the popup
    window.location.reload(false)
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  
  const handleSendMessage = async (e) => {
    e.preventDefault();
  
    const formData = {
      description: message,
    };
  
    try {
      const response = await fetch('https://api.kefetastartups.com/feedback/givefeedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        console.log('Feedback submitted successfully!');
        setSuccessMessage('Feedback submitted successfully!');
      } else {
        console.error('Failed to submit feedback');
        setSuccessMessage('Sorry, failed to submit feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSuccessMessage('Sorry, failed to submit feedback. Please try again.');
    }
  };
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const role= sessionStorage.getItem('role');
  let isadmin=false;
  
   if(role==='highAccess'){
    isadmin=true;
  }

  const [visible, setVisible] = useState(false);
  const toggleRef = useRef(false);


const handleClick = async () => {
  toggleRef.current = !toggleRef.current;
  setVisible(!toggleRef.current);  // Toggle the visibility immediately
  setSuccessMessage(null);

  // Add try-catch block to handle any errors in handleSendMessage
  try {

  //   await handleSendMessage();
    window.location.pathname='/';
  } catch (error) {
    console.error('Error handling click:', error);
  }
};


const {t,i18n}=useTranslation();

const [currentlanguage,setcuurentlan]=useState(i18n.language);



  const [text]=useTypewriter({
    words:i18n.language==="am"?['ለስራዎ፣','ለሀሳቦ፣','ለህልሞ']:['Startup','ሀሳብ','Business'],
    loop:{},
    typeSpeed:160,
    deleteSpeed:80
  })

  const handleContactClick = (rowData) => {
   // setSelectedContact(rowData);
    setVisible(true);  
    
  };

  const [langu, setlang]=useState(true)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setlang(!langu);
  };

  const changelangua=()=>{
setlang(!langu);
  }

  const scrollToFooter = () => {
    const footerElement = document.getElementById('footer');
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [displaypopupApply, setpopupApply]=useState(false)
  const popupApplyleave=()=>{

    setpopupApply(false);
  }
  const popupApply=()=>{

    setpopupApply(true);
  }
  const scrollToComponentB = () => {
    const componentB = document.getElementById('componentAbout');
    if (componentB) {
      componentB.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <div className='hero'>
       <div className="heroContents">
   
   

   <div className="heroContentTexts">
    <div className="heroSlogan">
      <h1>{t('homeHero.heroslogan')}</h1>
      <h1>{t('homeHero.heroslogan2')}</h1>
    </div>

    <div className="heroContenttext">
      <p dangerouslySetInnerHTML={{ __html: t("homeHero.heromessage") }}/>
    </div>


    <div className="heroContentbtn">{
  displaypopupApply &&
  <div className='displaypopupApply'>
<p>You have already applied</p>
    </div>
}
      <button  >{isLoggedIn==="true"? <Link onMouseOver={() => popupApply()} onMouseLeave={() => popupApplyleave()} to=""> {t('homeHero.herobtn')}</Link>:<Link to="/apply" > {t('homeHero.herobtn')}</Link>}</button>
<button ><Link className='investorsbtn' to="/investors">{t('homeHero.investors')}</Link></button>
   
    </div>
   </div>
      </div>
    <div className='heroImgandbg'>
    
    <div class="overlay">
      <Nav/>

     
    </div>

    <div style={{cursor:"pointer"}} onClick={scrollToComponentB} className='scrolldown'>
    <div className="scrolldownbtn">
<span>|</span>
    </div>
<span className="curved-text">Explore</span>
    </div>
    </div>
        
{visible && (
           <div className="companiesfeedback">
           <p>{t("homeHero.feedbacktop")} <span><MdEmojiEmotions className='feedback_emojy' /></span></p>
           <h2>{t("homeHero.feedbackhead")}</h2>
           <form>
                    <textarea
                      rows="7"
                      cols="40"
                      placeholder='Add message to send...'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                     
                    />
                  </form>
      
           <div className='cancel-ok'>
                    <button onClick={(e)=> handleSendMessage(e)} style={{backgroundColor:"rgb(38, 7, 215)",color:"white"}}>
                      {t("homeHero.feedbacksend")}
                    </button>
                    <Link onClick={handleClick} to='/'>
                     {t("homeHero.feedbackcancel")}
                    </Link>
                  </div>
                  
                  {successMessage && <div className='success-message-infeedback'>thank you for your feedback</div>}
           </div>
          
        )}
    </div>
  )
}

export default Hero