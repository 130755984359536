import { useState,useEffect } from 'react';
import Home from './pages/Home';
import {HashRouter, Routes,Route,Redirect} from 'react-router-dom'
import Apply from './pages/apply/Apply';
import Info from './pages/information/Info';
import ClimbingBoxLoader from "react-spinners/PropagateLoader";
import Terms from './pages/terms/Terms';
import Dashboard from './pages/dashboard/Dashboard';
import Feedbackview from './pages/feedback_view/Feedback_view';
import './App.css';
import './i18n.js'
import Privacy from './pages/privacy/Privacy';
import Settings from './components/settings/Settings';
import LanguageSelector from './components/languageselector/LanguageSelector';
import Userdash from './pages/userdashboard/Userdash';
import Userssettings from './pages/usersettings/Userssettings';
import Changestatus from './pages/changestatus/Changestatus';
import Usersendmessagetous from './pages/usersendmessagetous/Usersendmessagetous';
import Usersmessage from './pages/usersmessage/SeeUsersmessage';
import Usernotification from './pages/moduelpage/Usernotification';
import BlogContent from './pages/blogContents/BlogContent';
import ForgotPassword from './ForgotPassword';
import DocViewerComponent from './pages/usermodulechap/DocViewerComponent';
import Blogs from './pages/blogs/Blogs';
import Investors from './pages/investors/Investors';
import ServiceOffers from './pages/serviceOffers/ServiceOffers';
import Login from './Login';


function App() {

  const [loading, setLoading] = useState(true);
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="Appone">
      <div className={isPortrait ? "lockscreen" : "lockscreen hidden"}>
        <h1>Please rotate your device or use wider screen</h1>
      </div>
      {loading ?
        <ClimbingBoxLoader
          color={'#2729add5'}
          loading={loading}
          className='loaderr'
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> :
        <div className={isPortrait ? "App hidden" : "App"}>
      <HashRouter>
<Routes>

<Route exact path="/" element={<Home/>}/>
  return (
  <Route path="/apply" element={<Apply/>}/>
  <Route path="/info" element={<Info/>}/>
  <Route path="/terms" element={<Terms/>}/>
  <Route path="/privacy" element={<Privacy/>}/>
  <Route path="/dashboard" element={<Dashboard/>}/>
  <Route path="/feedbackview" element={<Feedbackview/>}/>
  <Route path="/settings" element={<Settings/>}/>
  <Route path="/userdash" element={<Userdash/>}/>
  <Route path="/changestatus" element={<Changestatus/>}/>
  <Route path="/userssettings" element={<Userssettings/>}/>
  <Route path="/usernotification" element={<Usernotification/>}/>
  <Route path="/usersmessage" element={<Usersmessage/>}/>
  <Route path="/forgotPassword" element={<ForgotPassword/>}/>
  <Route path="/usersendmessagetous" element={<Usersendmessagetous/>}/>
  <Route path="/languageSelector" element={<LanguageSelector/>}/>
  <Route path="/docViewerComponent/:head" element={<DocViewerComponent />} />
  <Route path="/ServiceOffers" element={<ServiceOffers />} />
  <Route path="/investors" element={<Investors />} />
  <Route path="/blogContent" element={<BlogContent />} />
  <Route path="/Blogs" element={<Blogs />} />
  <Route path="/login" element={<Login/>}/>
  
        
</Routes>
</HashRouter>
    
  </div>
    } 



     </div>
  );
}

export default App;