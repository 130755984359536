import React from 'react'
import './InfoHeader.css'
import imgheader from '../images/Newfolder/darkroom.png'


import { useTranslation } from 'react-i18next'
function InfoHeader() {

   const {t,i18n}=useTranslation();
   
  return (
    <>
    <div className='infoheader' style={{background:`url(${imgheader})`,backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}}>
<div className="info-header-text">
    <h1 dangerouslySetInnerHTML={{ __html: t('infopage.infoheader') }}></h1>
    <p>{t("infopage.infoheadermessage")} </p>
</div>
</div>


    
    </>
  )
}

export default InfoHeader