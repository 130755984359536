import { RiRefundFill } from "react-icons/ri";
import { FaSeedling } from "react-icons/fa6";
import { GrWorkshop } from "react-icons/gr";
import { MdOutlineGroups3 } from "react-icons/md";
import { SiFrontendmentor } from "react-icons/si"
import { GrBusinessService } from "react-icons/gr";

export default[
   
    {
        icon:MdOutlineGroups3,
    header:"Holistic Mentorship and Advisory Services",
    text:" Gain insights from seasoned entrepreneurs, industry experts, and professionals through mentorship sessions.Receive tailored guidance covering every aspect of your business journey."

     },
    {
        icon:RiRefundFill,
    header:"Strategic Investor Access and Funding Workshops",
    text:"Connect with venture capitalists, angel investors, and corporate partners through curated networking opportunities. Participate in pitch events and specialised workshops designed to help you secure crucial funding."

     },
     {
        icon:FaSeedling,
header:"Comprehensive Curriculum and Knowledge Resources",
text:"Access essential skills and knowledge through our comprehensive accelerator curriculum. Benefit from workshops, seminars, and a wealth of educational materials to drive your venture forward"
},
     {
        icon:GrWorkshop,
header:"Impact-Driven Programs",
text:"Our programs are designed with a focus on measurable outcomes and positive societal impact. Access opportunities and connections through our extensive network of partners, mentors, and alumni."
},
{
    icon:GrBusinessService,
header:"Comprehensive Support",
text:"We offer a full suite of support services, including mentorship, and networking opportunities, tailored to the unique needs of each start-up."

 },
    {
        icon:SiFrontendmentor,
    header:"Diverse Community",
    text:"We foster a vibrant and inclusive community that welcomes start-ups from all backgrounds."
     },
]