import React from 'react'
import './Program.css'
import leaf from '../images/Newfolder/Objects-removebg-preview.png'
import textimg from '../images/Newfolder/THE_PROGRAM-removebg-preview.png'
import Data from '../programs/Data'
import DataAmharic from '../programs/DataAmharic'
import vectorimg from '../images/Newfolder/VectorAb4.png'

import { useTranslation } from 'react-i18next'

function Program() {


   const {t,i18n}=useTranslation();
  return (
    <div className='program' style={{overflow:"hidden"}}>
       
      
        <div className="program-contents"  > 

<div className="program-content-header">
   {i18n.language==="en"? <img src={textimg} alt='for decoration'/>:<h1 style={{alignSelf:"flex-start",paddingLeft:"32px",fontStyle:"italic"}}>ስለፕሮግራሙ ብጥቂቱ</h1>}
    <div >

    {
       i18n.language==="en"? Data.map(items=>{
        return(
             <ul>
              <li><b>{items.header}</b></li>
              <li>{items.text}</li>
             </ul>
        )
       }):DataAmharic.map(items=>{
        return(
             <ul>
              <li><b>{items.header}</b></li>
              <li>{items.text}</li>
             </ul>
        )
       })
    }
    </div>
</div>
<div className="whyaccekeratr">
  <div>
<h1 className='whathappens'>{t("infopage.why")}</h1>
<h3  className='whathappensintro'>{t("infopage.why2")}</h3>
<p className="whyaccekeratrBlue">
{t("infopage.infofirstpar")}</p>
<p  dangerouslySetInnerHTML={{ __html: t('infopage.info2par') }}/>

<p  dangerouslySetInnerHTML={{ __html: t('infopage.info3par') }}/>

<p dangerouslySetInnerHTML={{ __html: t('infopage.info4par') }}/>

<p  dangerouslySetInnerHTML={{ __html: t('infopage.info5par') }}/>

<p dangerouslySetInnerHTML={{ __html: t('infopage.info6par') }}/>

<p  className="haravardlink" dangerouslySetInnerHTML={{ __html: t('infopage.info7par') }}/>

</div>

<div > 
<h1>{t("infopage.Areas")}</h1>
<p >
{t("infopage.areapara")}</p>
<ul className="info-last-div">

      <div>
        <h2>{t("infopage.Businessmodelstrategyhead")}</h2>
       <li>{t("infopage.Leadershipteam")}</li>
       </div>
      <div>
        <h2> {t("infopage.Usercentereddesignhead")}</h2>
      <li > {t("infopage.Technology")}</li>
      </div>
      <div>
        <h2> {t("infopage.Compliancehead")} </h2> 
      <li> {t("infopage.Relationalcapital")}</li>
      </div>
     <div>
      <h2> {t("infopage.Networkinghead")}</h2>
     <li > {t("infopage.Networking")}</li>
     </div>
     <div>
      <h2 dangerouslySetInnerHTML={{ __html: t("infopage.Networkinghead2") }}/>
     <li> {t("infopage.Networking2")}</li>
     <li> {t("infopage.Networking3")}</li>
     </div>
</ul>
    </div>


         </div>


        </div>
    </div>
  )
}

export default Program