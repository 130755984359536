import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import Nav from '../navadmin/Nav';
import Sidebar from '../sidebar/Sidebar';
import './settings.css';

const SettingsPage = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.kefetastartups.com/updateAdminSettings/updateadmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldPassword,
                    newPassword,
                    email
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setError('');
                sessionStorage.clear();
                sessionStorage.setItem('FirstName', data.FirstName);
                // navigate('/dashboard');
            } else {
                setError(data.message);
                setMessage('');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            } else {
                setError('Error occurred while updating settings');
            }
            setMessage('');
        }
    };

    return (
        <div className='dashboard'>
            <div className="dashboard_contents">
                <Sidebar />
                <main className="dashboard-main main-section setting-main">
                    <Nav />
                    <div className="add-cus-form-container settings-form">
                        <form>
                            <h2>Change Password </h2>
                            <div className='add-form-top settingsadmin-form'>
                                <div>
                                    <label>Previous Password</label>
                                    <input
                                        type="password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        className="input"
                                    />
                                </div>
                                <div>
                                    <label className="label">Previous Email:</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="input"
                                    />
                                </div>
                            </div>
                            <div className="inputContainer settings-bottominp">
                                <div>
                                    <label className="label">New Password:</label>
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="input"
                                    />
                                </div>
                                <div className="button-setting">
                                    <button style={{width:"80px",height:"38px", borderRadius:"8px",border:"1px solid black"}}onClick={handleSave} className="button">
                                        Save
                                    </button>
                                </div>
                                {message && <div className='adminupdayesucc' style={{ backgroundColor: 'green' }}><p>{message}<span> <Link to='/login' style={{color:"blue"}}>Login</Link></span></p></div>}
                                {error && <div className='adminupdayesucc' style={{ backgroundColor: 'red' }}><p>{error}</p></div>}
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SettingsPage;
