import React,{useState,useRef} from 'react'
import {Link} from 'react-router-dom'
import user from '../images/Newfolder/usericon-removebg-preview.png'
import './Nav.css'
import { FaLanguage } from "react-icons/fa6";
import i18n from 'i18next';
import { MdOutlineAccountCircle } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { VscClose } from "react-icons/vsc";
import LanguageSelector from '../languageselector/LanguageSelector';
import kefetaLogo from '../../components/images/Newfolder/logowithKefeta-removebg-preview.png'
import logocompany from '../images/Newfolder/logo12.png'
import { IoIosNotifications } from "react-icons/io";
import { FaBars } from "react-icons/fa6";
import { useTranslation } from 'react-i18next'
import lang from '../../components/images/Newfolder/Language_icon-removebg-preview.png'
import userimg from '../../components/images/Newfolder/usericon-removebg-preview.png'

function Nav({truth}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
// Function to toggle mobile menu
const toggleMobileMenu = () => {
  setMobileMenuOpen(!mobileMenuOpen);
};

  const [visible, setVisible] = useState(false);
  const toggleRef = useRef(false);
  const [active,setactive]=useState("nav_menu");
  const [toggleIcon,setToggleIcon]=useState("nav_toggler")

  const navToggle=()=>{
    active==="nav_menu"? setactive("nav_menu nav_active"):setactive("nav_menu")
toggleIcon==="nav_toggler"?setToggleIcon("nav_toggler toggle"):setToggleIcon("nav_toggler")
}

  const handleClick = () => {
    toggleRef.current = !toggleRef.current;
    setVisible(toggleRef.current);
  };
  
  let isadmin=false;
  const FirstName =localStorage.getItem('FirstName');
  const logedIn=localStorage.getItem('isLoggedIn');

  const email = localStorage.getItem('email');
  const password = sessionStorage.getItem('password');
  const role= localStorage.getItem('role');
  
  

  if(email==='Kefetastartupaccelerator@gmail.com' || email==='kefetastartupaccelerator@gmail.com'){
    isadmin=true;
    console.log('is ad :'+ isadmin)
    console.log('email:'+ email)
  }else{
    console.log('not admin :'+ isadmin)
    console.log('email:'+ email)
  }

  const handleLogout = () => {
      // Clear the session
      sessionStorage.clear();
      localStorage.clear();
      // Redirect the user to the login page
      window.location.href = '/';
    };const {t}=useTranslation();

    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

    const [langu, setlang]=useState(true)
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      setlang(!langu);
    };
  
    const changelangua=()=>{
  setlang(!langu);
    }
  const [displayNavLists, setdisplayNavLists]=useState(false);

    const showNavLists=()=>{
   setdisplayNavLists(!displayNavLists)
    }
  return (
    <header className="homenav homenavMobile">
      <div className="navigation_logo">
        {truth==='yes'?

<Link className="logoWithblack" to='/'><img src={logocompany} alt=''/></Link> :
      <Link className="logoWithwhite" to='/'><img  src={kefetaLogo} alt=''/></Link>
        }</div>
      {

isMobile? 
<>
<button className="menu-toggle" onClick={showNavLists}>
{!displayNavLists?<FaBars color= {truth==='yes'?'black':"white"} className='navToggleBtn Navopenbtn'/>:''}
</button>




{displayNavLists &&

<div className='displayLists menu-closing'>

<div className="navigation_account_and_lang">
<img src={lang} onClick={changelangua} alt=''/>

<button className="menu-toggle closeBar" onClick={showNavLists}>
{displayNavLists?<VscClose className='navToggleBtn'/>:''}
</button>
<h2 className="nav-links-li" > 
{logedIn==="true"? <span className='accountname'><Link to={isadmin? "/dashboard":'/userdash'}>{FirstName}</Link>
</span> : <Link to='/login' className='nav_acc'><img  src={userimg} alt=''/>
 </Link>}
 </h2>


</div>


<div className="navigation_links_list" >
<Link to='/ServiceOffers' > {t("homeNav.offerService")}</Link>  
<Link to='/info' > {t("homeNav.navabout")}</Link>  
<Link to='/apply' > {t("homeNav.navapply")}</Link>  
<Link to='/blogs' > {t("homeNav.blog")}</Link>
{isadmin && <Link to="/dashboard">Analytics</Link>}

</div>

</div>
}
</>

: 
<div className={`nav_linkAndAcco ${displayNavLists?'displayLists':'' }`}>
<div className="navigation_links_list" >
<Link to='/ServiceOffers' > {t("homeNav.offerService")}</Link>  
<Link to='/info' > {t("homeNav.navabout")}</Link>  
<Link to='/apply' > {t("homeNav.navapply")}</Link>  
<Link to='/blogs' > {t("homeNav.blog")}</Link>  
{isadmin && <Link to="/dashboard">Analytics</Link>}

</div>

  
<div className="navigation_account_and_lang">

<FaLanguage className='navIcons'  color= {truth==='yes'?'black':"white"} onClick={changelangua}/>
 
<h2 className="nav-links-li" > 
{logedIn==="true"? <span className='accountname'><Link to={isadmin? "/dashboard":'/userdash'}>{FirstName}</Link>
</span> : <Link to='/login' className='nav_acc'><FaUser className='navIcons' color={truth==='yes'?'black':"white"}/>
 </Link>}
 </h2>


</div>
</div>
      }


        {
visible &&
<div className={`logout-cont ${toggleRef}`} style={{zIndex:"70"}}>
    <h2>Logout</h2>
    <div>
    <button onClick={handleLogout}>
        Ok
      </button>
    <Link onClick={handleClick} to='/'>
        Cancel
      </Link>
      </div>
</div>
      }

{!langu  && 
       <ul className='langaugesel_lang'>
       <p> Select Language</p>
        <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('am')}>አማርኛ</button>
      </div>
      <button className='lan_closebtn'onClick={changelangua}>Close</button>
      </ul>
}
    </header>
  )
}

export default Nav