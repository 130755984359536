import React,{useState,useRef} from 'react'
import {Link} from 'react-router-dom'
import './Blogs.css'
import Footer from '../../components/footer/Footer'
import Nav from '../../components/nav/Nav'
import { FaArrowRight } from "react-icons/fa";
import { CiCircleChevRight } from "react-icons/ci";
import img1 from '../../components/images/Newfolder/capitalMarket.jpg'
import img8 from '../../components/images/Newfolder/aboutimg3.jpg'
import img2 from '../../components/images/Newfolder/man_using_phone_tablet-transformed3.png'
import img3 from '../../components/images/Newfolder/new-tech.jpeg'
import img4 from '../../components/images/Newfolder/teacher19.png'
import img5 from '../../components/images/Newfolder/blogpic2.jpg'
function Blogs() {
  
  return (
    <>
    <Nav  truth='yes'/>
    <div className='blogsSection'>
<div className="blogsSection_left">
    <h2 className="blog_heads blog_heads1">Recent Posts</h2>

    <ul>
    <li>
            <img src={img1} alt=''/>
            <div>
            <div>
                <h2>What’s capital market in Ethiopia?</h2>
                <p>We will be covering the capital market landscape in Ethiopia,
                     because the company is...  </p>
            </div>
            <Link to='/blogContent'><CiCircleChevRight className='blogListIcons'/></Link>
            </div>
        </li>
        
        <li>
            <img src={img8} alt=''/>
            <div>
            <div>
                <h2>Kefeta is proceeding its first round. </h2>
                <p>Kefeta will begin its first round program with its selected startups.</p>
            </div>
            <Link to=''><CiCircleChevRight className='blogListIcons'/></Link>
            </div>
        </li>
        

        <li>
            <img src={img5} alt=''/>
            <div>
            <div>
                <h2>AI in ethiopia and its impact on students</h2>
                <p>We have new guests joining us today to discuss Ai in ethiopia. </p>
            </div>
            <Link to=''><CiCircleChevRight className='blogListIcons'/></Link>
            </div>
        </li>

        <li>
            <img src={img4} alt=''/>
            <div>
            <div>
                <h2>Lessons on building hardware from the founders of Eight Sleep </h2>
                <p>The co-founders of Eight Sleep share some things they've learned about building hardware over the last 10 year.</p>
            </div>
            <Link to=''><CiCircleChevRight className='blogListIcons'/></Link>
            </div>
        </li>
    </ul>
</div>

<aside className="blogsSection_aside">
<h2 className="blog_heads">Whats here</h2>

<ul>
    <li>
        <p>1</p>
        <p>Upcoming events</p>
    </li>
    <li>
        <p>2</p>
        <p>Blogs</p>
    </li>
    <li>
        <p>3</p>
        <p>News</p>
    </li>
    <li>
        <p>4</p>
        <p>Stories</p>
    </li>
   
</ul>
</aside>


    </div>
    <Footer/>
</>
  )
}

export default Blogs