import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import './Signup.css';
import { useTranslation } from 'react-i18next'

function Login() {

 const {t}=useTranslation(); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
 const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
   //   const response = await fetch('https://nodekefetastdomainn.kefetastartups.com:443/authenticate', {
  
   const response = await fetch('https://api.kefetastartups.com/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('FirstName', data.FirstName);
        sessionStorage.setItem('LastName', data.LastName);
        sessionStorage.setItem('phonenumber', data.PhoneNumber);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('token', data.token);
       
        //localStorage
        localStorage.setItem('FirstName', data.FirstName);
        localStorage.setItem('LastName', data.LastName);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('phonenumber', data.PhoneNumber);
        localStorage.setItem('email', email);
        localStorage.setItem('token', data.token);

if(email === 'Kefetastartupaccelerator@gmail.com' || email === 'kefetastartupaccelerator@gmail.com'){
  sessionStorage.setItem('role', 'highAccess');
  navigate('/dashboard');
}else{
  sessionStorage.setItem('role', 'userAccess');
  navigate('/userdash');
}
        

      } else {
        setError(data.error);
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setError('Please double check your credentials!');
    }
  };


  return (
    <div className="signup-log">
      <Form onSubmit={handleSubmit}>
        <div className="signuploginform-head vis">
          <p>
            <Link className='loginlink' to="/login" style={{color:"white"}}> {t("login.Login")}</Link>
          </p>
        </div>
        <div className="signlo-formgroup">
          <Form.Group className="sign-lab-inp" controlId="formBasicEmail">
            <Form.Label className="em-samp">{t("login.Email")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                backgroundColor: 'rgb(62, 65, 87)',
                borderRadius: '13px',
                color: 'rgb(175, 175, 177)',
                width: '93%',
                padding: '8px 15px',
                border: 'none',
              }}
            />
          </Form.Group>
          <Form.Group className="sign-lab-inp" controlId="formBasicPassword">
            <Form.Label className="em-samp">{t("login.Password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                backgroundColor: 'rgb(62, 65, 87)',
                borderRadius: '13px',
                color: 'rgb(175, 175, 177)',
                width: '93%',
                padding: '8px 15px',
                border: 'none',
              }}
            />
          </Form.Group>
          <div className='forogtpass'>
            <Link to="/forgotPassword">{t("login.ForgotPassword")}</Link>
          </div>
          <Button className="signup-btn" variant="primary" type="submit">
            {t("login.Login2")}
          </Button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      </Form>
    </div>
  );
}

export default Login;
