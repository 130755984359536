import React,{useState,useRef} from 'react'
import {Link} from 'react-router-dom'
import Data from './Data'
import DataAmharic from './DataAmharic'
import './ServiceOffers.css'
import { RiRefundFill } from "react-icons/ri";
import { FaSeedling } from "react-icons/fa6";
import { GrWorkshop } from "react-icons/gr";
import { MdOutlineGroups3 } from "react-icons/md";
import { SiFrontendmentor } from "react-icons/si"
import { GrBusinessService } from "react-icons/gr";
import Footer from '../../components/footer/Footer'
import Nav from '../../components/nav/Nav'
import { useTranslation } from 'react-i18next'

function ServiceOffers() {
  


    const {t,i18n}=useTranslation();


    const roadsection=i18n.language==="en"? Data.map(item=>{ 
        const IconComponent = item.icon;
        return(
           
              <li className="serviceAndOffers_bottomlists">
        <div>
            
            <IconComponent color='#DAD2D8'  className='serviceOffers_icons'/>
           <h2>{item.header}</h2>
        </div>
        <p>{item.text}</p>
    </li>
            
        )
    }):DataAmharic.map(item=>{ 
        const IconComponent = item.icon;
        return(   
            <li className="serviceAndOffers_bottomlists">
            <div>
                <IconComponent  className='serviceOffers_icons'/>
               <h2>{item.header}</h2>
            </div>
            <p>{item.text}</p>
        </li>
        )
    })




  return (
    <>
        <Nav truth='yes'/>
    <div className='serviceAndOffers'>
<div className="serviceAndOffers_top">
<p style={{color:"#797979"}}>{t("homeabout.ServiceAndOffers")}</p>
<div>
<h1>{t("homeabout.Providetoolsfor")} </h1>
<h1 dangerouslySetInnerHTML={{ __html: t('homeabout.Startups') }}/>
</div>
</div>


<div className="serviceAndOffers_bottom">
<ul>
{roadsection}
</ul>
</div>
    </div>
<Footer/>
</>
  )
}

export default ServiceOffers