import React, { useEffect,useState } from 'react';
import './Footer.css';
import '../languageselector/LanguageSelector.css';
import { Link } from 'react-router-dom';
import kefetaLogo from '../../components/images/Newfolder/logowithKefeta-removebg-preview.png'
import insta from '../images/Newfolder/Instagram-removebg-preview.png';
import linkdin from '../images/Newfolder/LinkedIn-removebg-preview.png';
import twitter from '../images/Newfolder/twitter.png';
import { FaXTwitter } from "react-icons/fa6";
import LanguageSelector from '../languageselector/LanguageSelector';
import { useTranslation } from 'react-i18next'

import './Footer.css'; // Import the CSS file for styling
const Footer = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []); // Empty dependency array ensures this effect runs only once

  const {t,i18n}=useTranslation();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const role= sessionStorage.getItem('role');
  const [langu, setlang]=useState(true)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setlang(!langu);
  };

  const changelangua=()=>{
setlang(!langu);
  }
const currentyear= new Date().getFullYear()

   const email = sessionStorage.getItem('email');

  let isadmin=false;
   if(role==='highAccess'){
    isadmin=true;
  }


  
  return (
    <footer className="footer">
      <div className="footer-top">
        <img src={kefetaLogo} alt="Footer Logo" className="footer-logo" />
      </div>
      <div className="footer-content">
        <div className="footer-section">
          <h4>{t('footer.about')}</h4>
          <ul>
             <li><Link to="/info">{t("footer.aboutus")}</Link></li>
           <li> <Link to="/apply">{t("footer.apply")}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('footer.info')}</h4>
          <ul>
             <li><Link to='/investors' >{t('homeHero.forinvestors')}</Link></li>
            <li><Link to='/ServiceOffers' >{t('homeNav.offerService')}</Link></li> 
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('footer.TERMS')}</h4>
          <li> <Link to="/terms">{t("footer.TERMSOFSERVICE")}</Link></li>
          <li><Link to="/privacy">{t("footer.PRIVACY")}</Link></li>
          <li> <Link to="" className='footer_language' onClick={changelangua} style={{cursor:"pointer"}}>Language</Link></li>
          
        </div>
      </div>

      {!langu  && 
       <ul className='langaugesel_lang'>
       <p> Select Language</p>
        <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('am')}>አማርኛ</button>
      </div>
      <button className='lan_closebtn'onClick={changelangua}>Close</button>
      </ul>
}
      <div className="footer-bottom">
      <p>&copy; {t("footer.rights")} {currentyear} {t("footer.rights2")} </p>
      </div>
    </footer>
  );
}

export default Footer;
