import React,{useRef,useState}from 'react'
import {Link} from 'react-router-dom'
import './Nav.css'
import { MdOutlineAccountCircle } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";



function Nav() {
    const [visible, setVisible] = useState(false);
    const toggleRef = useRef(false);
  
    const handleClick = () => {
      toggleRef.current = !toggleRef.current;
      setVisible(toggleRef.current);
    };


    const name = sessionStorage.getItem('FirstName');
    const handleLogout = () => {
        // Clear the session
        sessionStorage.clear();
        localStorage.clear();
        // Redirect the user to the login page
        window.location.href = '/';
      };
      
    return (
    
    <div className="navigation-bar">
        <ul><li onClick={handleClick} >
           {name?name: <Link to='/login'><MdOutlineAccountCircle className='icons-nav'
            style={{color:'black'}}/> </Link>}</li>
</ul>

{
visible &&<div className={`logout-cont ${toggleRef}`} >
    <h2>Logout</h2>
    <div>
    <button onClick={handleLogout}>
        Ok
      </button>
    <Link onClick={handleClick} to='/'>
        Cancel
      </Link>
      </div>
</div>
      }
</div>

    )
}
export default Nav