import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import { FaLocationDot } from "react-icons/fa6";
import { MdSupportAgent } from "react-icons/md";
import { LiaTruckLoadingSolid } from "react-icons/lia";
import ClimbingBoxLoader from "react-spinners/PropagateLoader";
import ellipse from '../../components/images/Newfolder/vectorcircle1.png'
import formimg from '../../components/images/Newfolder/man_working_online-removebg-preview.png'
import sideimg from '../../components/images/Newfolder/apply_sideimg.jpg'
import './Apply.css'
import { IoIosStarHalf } from "react-icons/io";
import ReactGA from "react-ga4"
import { useTranslation } from 'react-i18next'

function Apply() {
  
  const [successMessage, setSuccessMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  let bgvisible= submitting?"blurry":"visible";
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    number: '',
    description: '',
    company_name: '',
    file: null,
  });

   const {t}=useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: 'User Apply',
      action: 'Clicked Apply',
      label: 'Apply button',
    });

    if (formData.password.length < 8) {
      setSuccessMessage('Password must be at least 8 characters long');
      return;
    } else {
      if (formData.file == null) {
        setSuccessMessage('Woops! Make sure some of the input are filled');
      } else {
        setSubmitting(true);
        // You can send the form data to the server here using fetch or axios
        const formDataToSend = new FormData();
        formDataToSend.append('firstname', formData.firstname);
        formDataToSend.append('lastname', formData.lastname);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('number', formData.number);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('company_name', formData.company_name);
        formDataToSend.append('file', formData.file);

        try {
          const response = await fetch('https://api.kefetastartups.com/submitForm/submitUserForm', {
            method: 'POST',
            body: formDataToSend,
          });

          const responseData = await response.json(); // Parse response body as JSON

          if (response.ok) {
            console.log('Form submitted successfully!');
            setSuccessMessage('Form submitted successfully!');
            sessionStorage.setItem('phonenumber', formData.number);
          } else {
            console.error('Failed to submit form:', responseData.message); // Log the error message
            setSuccessMessage(responseData.message); // Update the error message state
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setSuccessMessage('Woops! Make sure the inputs are correct.'); // Update the error message state
        } finally {
          setSubmitting(false); // Stop submitting
        }
      }
    }
  };


  const containerStyle = {
    position: 'relative',
    backgroundImage: `url(${sideimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
     // Adjust as needed for your layout
  };


  

  return (
    <div className={`Apply ${bgvisible}`} >
       <div className="apply-contents">
       {successMessage && 
  <div className='success-message2'>
    <p style={{color:"black"}}>{successMessage}</p>
    {successMessage === "Form submitted successfully!" ? 
      <div>
        <p>Click here to navigate to </p>
        <Link to="/login">Login</Link>
      </div>
      : null
    }
    <button className='apply-popupbtn' style={{width:"70px",alignSelf:"end"}} onClick={() => setSuccessMessage('')}>OK</button>
  </div>
}
     


{submitting && 

<div className="sppinerdiv">
  
<ClimbingBoxLoader
          color={'hsla(0, 90%, 50%, 1)'}
          loading={submitting}
          className='loaderr'
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> 
  </div>
  }

        <div className="form-content">
        <div className="form-and-side">


          <div className='form-sidenote' style={containerStyle}>
            <div className='form-sidenote-wrap' >
            <div className='form-sidenote-wrap2'>
              <h2>{t("apply.noteHead")}</h2>
         <p className='form-p'  ><span style={{display:"flex",gap:"12px"}}><IoIosStarHalf size={47}/>{t("apply.notes1")}</span></p>

          

            </div>

            <div className='form-sidenote-wrap-bottom'>
            <div>
              <p style={{color:"white"}}><MdSupportAgent  style={{color:"white"}}/> <span>{t("apply.CustomerSupport")}</span></p>
         <p style={{color:"rgba(161, 109, 12, 0.961)"}}>+251900062616</p>
            </div>

           
            </div>
            
          </div>
          </div>
          
          <form onSubmit={handleSubmit}>
          <div className='applyform-top'>
  <div>
    <label>{t("apply.FirstName")}</label>
    <input type="text" name="firstname" value={formData.firstname} onChange={handleInputChange} />
  </div>
  <div>
    <label>{t("apply.LastName")}</label>
    <input type="text" name="lastname" value={formData.lastname} onChange={handleInputChange} />
  </div>
</div>
<div className='applyform-top'>
  <div>
    <label>{t("login.Password")}</label>
    <input type="password" name="password" value={formData.password} onChange={handleInputChange} />
  </div>

  
     <div>
     <label>{t("login.Email")}</label>
     <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
    </div>
    
     </div>
     <div className='applyform-top'>
     <div>
      <label>{t("apply.CompanyName")} <span className='optional-lable'>{'('}optional{')'}</span></label>
     <input name="company_name" value={formData.company_name} onChange={handleInputChange} />
     </div>
       
     <div>
      <label>{t("footer.Phone")}</label>
     <input type="text" name="number" value={formData.number} onChange={handleInputChange} />
     </div>
     </div>
     <div className='applyform-top'>

      <div>
     <label>{t("apply.info")}</label>
     <textarea name="description" value={formData.description} rows="7" cols="30" style={{backgroundColor:"#cfcdcd"}} onChange={handleInputChange} />
     </div>
     <div>
     <label className='fileattachment-lable'>{t("apply.attachment")} <span className='optional-lable'> {'('}pdf,docx{')'}</span></label>
     <input type="file" name="file" className='fileattachment' onChange={handleFileChange} />
</div>
    </div>
    <div className='applyform-bottom'>
    
    <div>
      <p  className="agree" dangerouslySetInnerHTML={{ __html: t("apply.aggrement") }} />
      </div> 
      <hr>
      </hr>
      <div>
          <button type="submit" className='btn'>{t("apply.apply")}</button>
          </div>
          </div>
          </form>
          </div>

          <div className="form-img">
            <img src={formimg} alt='' />
          </div>
        </div>
          <div className="apply-ellipse">
<img src={ellipse} alt=''/>
          </div>
       </div>
    </div>
  )
}

export default Apply