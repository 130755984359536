import React from 'react'
import './Contactus.css'
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import imgbg from '../../components/images/Newfolder/aboutimg1.jpg'
import fb from '../../components/images/Newfolder/facebookicon8.png'
import insta from '../../components/images/Newfolder/insta2-removebg-preview.png'
import twitter from '../../components/images/Newfolder/TwitterX.png'
import ticktock from '../../components/images/Newfolder/ticktock2.png'
import linkden from '../../components/images/Newfolder/LinkedInicon-removebg-preview.png'
import { FaInstagramSquare } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
function Contactus() {
  const {t,i18n}=useTranslation();

  return (
    <div className="contactus_section" >
        <div className="contactus_section_right" >
.
      </div>
      <div className="contactus_section_contents">

       <div className="contactus_section_header">
        <h1>{t('homecontact.Contactus')} </h1>
        <h1>{t('homecontact.Through')}</h1>
        
       <span>.</span>

       

        </div> 

       <div className="contactus_section_contacts">
       <div><MdEmail className='contact_icons' size={40}/>  <a href='maileto@Kefetastartupaccelerator@gmail.com' >Kefetastartupaccelerator@gmail.com</a></div> 
       <div><IoLocation className='contact_icons' size={40}/>  <a href='tel:+1234567890' >{t('homecontact.EthiopiaAddisAbaba')}</a></div> 
       <div><IoCallSharp className='contact_icons' size={40}/>  <a href='tel:+251900062616' >+251900062616</a></div>
        
        </div> 


<div className="contactus_section_socials">

<a href="https://www.facebook.com/profile.php?id=61558690284667"  target='_blank'><img src={fb} alt=''/></a>
<a href="https://www.instagram.com/kefeta_accele/" target='_blank' ><img src={insta} alt=''/></a>
<a href="#" target='_blank' ><img src={linkden} alt=''/></a>
<a href="#"  target='_blank'><img src={ticktock} className='contact_icons' alt=''/></a>
<a href="https://twitter.com/KefetaStartup"  target='_blank'><img src={twitter} className='contact_icons' alt=''/></a>



</div>
      </div>


      
    </div>
  )
}

export default Contactus