import React from 'react';
import {Link} from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
// src/components/MainContent.js
import imgbg from '../../components/images/Newfolder/bgcontact.jpg'

import './Investors.css';
import { useTranslation } from 'react-i18next'
import Footer from '../../components/footer/Footer'
import Nav from '../../components/nav/Nav'

const Investors = () => {
    const {t,i18n}=useTranslation();
   
    return (
    <>
    
    <Nav truth="yes"/>
    <div className='blog-postContent'>
    <Container className="blog-post">
      <Row className='rowBlogTopHead'>
        <Col md={8} className="mx-auto">
          <Card className="shadow-lg">
            <CardBody>
              <CardTitle className="text-center">
                <h1> {t("investors.FORINVESTORS")}</h1>
              </CardTitle>
              
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5 rowBlogHeadquestion">
        <Col md={4} className="mx-auto">
          <Card className="shadow-lg shadow-lg2">
            <CardBody>
              <CardTitle>
                <h2>{t("investors.Accesshig")}</h2>
              </CardTitle>
              <CardText className='blogCntentText'>
                <p style={{marginTop:"62px"}}>{t("investors.firstText")}
</p>
              </CardText>
              
            </CardBody>
          </Card>
        </Col>
        
      </Row>


      <Row className="mt-5 rowBlogHeadquestion">
        <Col md={4} className="mx-auto">
          <Card className="shadow-lg shadow-lg2">
            <CardBody>
              <CardTitle>
                <h2>{t("investors.StrategicNetworking")}</h2>
              </CardTitle>
              <CardText className='blogCntentText'>
                <p style={{marginTop:"62px"}}>{t("investors.SECText")}
</p>
              </CardText>
              
            </CardBody>
          </Card>
        </Col>
        
      </Row>
      <Row className="mt-5 rowBlogHeadquestion">
        <Col md={4} className="mx-auto">
          <Card className="shadow-lg shadow-lg2">
            <CardBody>
              <CardTitle>
                <h2>{t("investors.DueDiligencesupport")} </h2>
              </CardTitle>
              <CardText className='blogCntentText'>
                <p style={{marginTop:"62px"}}>{t("investors.ThiredText")}
</p>
              </CardText>
              
            </CardBody>
          </Card>
        </Col>
        
      </Row>

     
      <Row className="mt-5 rowBlogHeadquestion WhyPartner">
        <Col md={4} className="mx-auto">
          <Card className="shadow-lg shadow-lg2 WhyPartnertext">
            <CardBody>
              <CardTitle>
                <h1>{t("investors.WHYPARTNER")}</h1>
              </CardTitle>
              <CardText className='blogCntentText blogCntentText2'>
             <h3 style={{marginTop:"62px"}}>{t("investors.ExtensiveNetwork")}</h3>
                <p style={{marginTop:"22px"}}>{t("investors.FourthText")}
</p>
             <h3>{t("investors.ProvenRecord")}</h3>
                <p style={{marginTop:"22px"}}>
{t("investors.FivethText")}

</p>
              </CardText>
              
            </CardBody>
          </Card>
        </Col>
        
      </Row>

     
    </Container>
    <aside className='blogContentAside'>
    <h3>{t("investors.asidehead")}</h3>
    <ul >
<li><Link to="/info" >{t("investors.asidelink1")}</Link></li>
<li><Link to="/Blogs" >{t("investors.asidelink2")}</Link></li>
</ul>


    </aside>
    </div>
   <Footer/>
</>

);
}

export default Investors;