import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Signup.css';
import './forgetPass.css';
import { useTranslation } from 'react-i18next'

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [resetCodeSent, setResetCodeSent] = useState(false);
    const [verificationError, setVerificationError] = useState('');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [passwordResetError, setPasswordResetError] = useState(false);
    const [newpasswordSuccess, setnewPasswordSuccess] = useState(false);
    const [timer, setTimer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailNotFoundError, setEmailNotFoundError] = useState(false); // State to indicate email not found error

     const {t}=useTranslation(); 
    useEffect(() => {
        if (resetCodeSent) {
            const timerId = setTimeout(() => {
                setResetCodeSent(false);
            }, 3000000); // 2 minutes
            setTimer(timerId);
        } else {
            clearInterval(timer);
        }
    }, [resetCodeSent]);

    const handleEmailSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.post('https://api.kefetastartups.com/resetPassword', { email });
            setResetCodeSent(true);
            setEmailNotFoundError(false); // Reset email not found error state
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 404) {
                setEmailNotFoundError(true);
            }
        } finally {
            setLoading(false);
        }
    };


    const handleVerificationSubmit = async () => {
        try {
            setLoading(true);
            await axios.post('https://api.kefetastartups.com/verifyCode/verify', { email, code });
            setPasswordResetSuccess(true);
            clearInterval(timer); // Clear the timer
        } catch (error) {
            setVerificationError('Invalid code');
        } finally {
            setLoading(false);
        }
    };

const handlePasswordResetSubmit = async () => {
        try {
            setLoading(true);
            // Check if new password meets length requirement
            if (newPassword.length < 8) {
                setPasswordLengthError(true);
                return;
            }
            await axios.post('https://api.kefetastartups.com/newpassword/resetUserPassword', { email, newPassword });
            setPasswordLengthError(false);
            setPasswordResetError(false);
            setnewPasswordSuccess(true);
        } catch (error) {
            setPasswordResetError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="signup-log signup-log2 signup-log-FORGET">
            <Form className='signup-log-FORGETform'>
                <div className="signuploginform-head vis">
                    <p>
                        <Link className='loginlink' to="/login" style={{ color: "black" }}>{t("login.ResetPassword")}</Link>
                    </p>
                </div>
                <div className="signlo-formgroup" >
                   
                    {resetCodeSent ? (
                        <div>
                            {passwordResetSuccess ? (
                                <div className="reset-div">
                                    <p style={{ color: "white" }}>{t("login.Resetsuccessful")}</p>
                                    <div>
                                        <input className='reset_inp' type="password" placeholder="New password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                        <button onClick={handlePasswordResetSubmit} disabled={loading}>{t("login.SetNewPassword")}</button>
                                        {loading && <Spinner animation="border" role="status" />}
                                    </div>
                                    {passwordLengthError && <p style={{ color: "red" }}>{t("login.Passwordlength")}</p>}
                                    {passwordResetError && <p style={{ color: "red" }}>{t("login.Errorresetting")}</p>}
                                    {newpasswordSuccess && <p style={{ color: "white" }}>{t("login.Resetsucces")} <Link style={{fontSize:"0.7rem"}} to="/login" className='navto-login'>{t("login.Login2")}</Link></p>}
                                </div>
                            ) : (
                                <div className="reset-div" >
                                    <p style={{ color: "green" }}>{t("login.verificationsent")}</p>
                                    <div>
                                        <input className='reset_inp reset_inpcode' type="text" placeholder="Enter code" value={code} onChange={e => setCode(e.target.value)} />
                                        <button onClick={handleVerificationSubmit} disabled={loading}>{t("login.Submit")}</button>
                                        {loading && <Spinner animation="border" role="status" />}
                                    </div>
                                    <p style={{ color: "grey" }} ><span style={{ color: "red", marginRight:"4px", fontSize:"0.87rem" }}> 2 </span> {t("login.Minuteremaining")}</p>
                                    {verificationError && <p style={{ color: "red" }}>{verificationError}</p>}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='inp-btn'>
                            <input className='reset_inp' type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                            <button onClick={handleEmailSubmit} disabled={loading}>{t("login.SendResetCode")}</button>
                            {loading && <Spinner animation="border" role="status" />}
                            {emailNotFoundError && <p style={{ color: "red" }}>{t("login.Emailnotfound")}</p>} {/* Render if email not found */}
                   
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
}

export default ForgotPassword;